import HttpClient from "./http-client";
import AuthenticationService from "./authentication/AuthenticationService";

export default class ApiClient extends HttpClient {
    constructor(baseURL = process.env.REACT_APP_API_URL, langCode = "fr") {
        super({
            baseURL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                lang: langCode
            }
        });
    }

    get data() {
        return {
            getCommonData: (email, offset) => this.post('/common', { email, offset }),
        }
    }

    get user() {
        const authenticationService = new AuthenticationService();

        return {
            doRegister: (user) => this.post("/user/register", user),
            getUser: (email) => this.post("/user", { email }),
            getToken: () => authenticationService.getToken(),
            storeToken: (token) => authenticationService.storeToken(token),
            deleteToken: () => authenticationService.deleteToken()
        }
    }

    get membership() {
        return {
            generatePaymentIntent: (customer, token, plan, email, coupon) => this.post("/membership", { customer, token, plan, email, coupon }),
            createSubscription: (customer, paymentId, price, plan, email, tipsters, coupon) => this.post("/membership/subscription", { customer, paymentId, price, plan, email, tipsters, coupon }),
            checkCoupon: (coupon, plan) => this.post("/membership/coupon", { coupon, plan }),
        }
    }

    get tipster() {
        return {
            getTipster: (email, slug, offset) => this.post(`/tipster/${slug}/${offset}`, { email }),
            getLastTwentyPublishedPrognoses: () => this.get("/tipster/prognosis"),
            publishNewPrognosis: (tipster, prognosis) => this.post("/tipster/prognosis", { tipster, prognosis }),
            getPrognosisById: (id) => this.get(`/tipster/prognosis/${id}`),
            updatePrognosis: (id, tipster, prognosis) => this.patch(`/tipster/prognosis/${id}`, { tipster, prognosis }),
            getAwaitingValidationPrognoses: () => this.get("/tipster/prognosis/awaiting-validation"),
            setAwaitingValidationPrognosisValidated: (prognosis) => this.patch("/tipster/prognosis/awaiting-validation", { prognosis }),
            updateStatistics: (statistics) => this.patch("/tipster/statistics", statistics)
        };
    }

    get admin() {
        return {
            getAllTipsters: () => this.get(`/admin/tipsters`),
            getAllPrognoses: () => this.get("/admin/prognosis"),
            publishNewPrognosis: (tipster, prognosis) => this.post("/admin/prognosis", { tipster, prognosis }),
            getPrognosisById: (id) => this.get(`/admin/prognosis/${id}`),
            updatePrognosis: (id, tipster, prognosis) => this.patch(`/admin/prognosis/${id}`, { tipster, prognosis }),
            getAllUsers: () => this.get(`/admin/users`),
            getUserByEmail: (email) => this.get(`/admin/users/${email}`),
            giveFreeMembership: (user, subscribedTipsters, plan, trialPeriod) => this.post("/admin/free-membership", { ...user, subscribedTipsters, plan, trialPeriod }),
            getAllStats: () => this.get(`/admin/stats/all`),
            getStatsByTipster: (tipster) => this.get(`/admin/tipster/stats/${tipster}`),
        }
    }
}
