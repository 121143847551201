import React  from 'react';
import {
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import { useStateValue } from "../membership-state-context";
import './MembershipOffersForm.scss';
import {Alert} from "@material-ui/lab";
import AuthenticationService from "../../../services/authentication/AuthenticationService";
import {withRouter} from "react-router-dom";

const MembershipOffersForm = ({ onSelect, ...props }) => {

    const authenticationService = new AuthenticationService();

    const [, dispatch] = useStateValue();

    const onOfferClicked = (offer) => {
        if (authenticationService.getToken()) {
            dispatch({
                type: 'editFormValue',
                key: 'selected_offer',
                value: offer
            });

            onSelect(offer);
        } else {
            props.history.push('/connexion');
        }
    }

    return <>
        <Grid item xs={12}>
            <Typography variant="h6">Nos abonnements :</Typography>
        </Grid>
        <Grid item xs={12}>
            <Alert variant="filled" severity="info" style={{ fontSize: 18, fontWeight: "bold", textTransform: "uppercase" }}>
                Offre de lancement !
            </Alert>
        </Grid>
        <Grid item xs={12} sm={8} style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 30 }}>
            <div className={ "LimitedOffer" }>
                <h1 style={{ marginBottom: 15 }}>Pack ultimate</h1>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="LimitedOffer__NewPrice">
                        <p className={ "Big" }>15</p>
                        <div className={ "Right" }>
                            <p className={ "Small" }>€ par</p>
                            <p className={ "Small" }>mois</p>
                        </div>
                    </div>

                    <p style={{ margin: "0 20px" }}>ou</p>

                    <div className="LimitedOffer__NewPrice">
                        <p className={ "Big" }>120</p>
                        <div className={ "Right" }>
                            <p className={ "Small" }>€ par</p>
                            <p className={ "Small" }>an</p>
                        </div>
                    </div>
                </div>

                {/* <p style={{ textAlign: "center", marginTop: 0 }}>au lieu de <span style={{ color: "#dc3545", fontWeight: "bold", textDecoration: "line-through" }}>25€/mois</span></p> */}

                <p className={ "Description" }>Grâce à cette offre, débloquez un accès intégral aux pronostics de nos tipsters!</p>

                <Button
                    style={{ width: 200, margin: "30px auto 0" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={ () => onOfferClicked("three") }>
                    J'en profite !
                </Button>
            </div>
        </Grid>
        { /*<Grid item xs={12} sm={6} style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 30 }}>
            <div className="membership-offer-container" onClick={ () => onOfferClicked("three") }>
                <p className="membership-offer-title">Pack ultimate</p>
                <p className="membership-offer-price" style={{ fontWeight: "bold", color: "red", marginTop: 8, marginBottom: 0 }}>15€/mois ou 120€/an</p>
                <p className="membership-offer-price" style={{ marginBottom: 15, fontSize: 16 }}>au lieu de <span style={{ textDecoration: "line-through" }}>25€/mois</span></p>

                <p className="membership-offer-description">Grâce à cette offre, ne faites aucun compromis ! Débloquez l'accès aux pronostics de tous nos tipsters.</p>
                <p className="membership-offer-description">Grâce à cette offre, débloquez l'accès aux pronostics de tous nos tipsters.</p>
            </div>
        </Grid>*/ }
        { /* <Grid item xs={12} sm={4} style={{ marginBottom: 30 }}>
            <div className="membership-offer-container" onClick={ () => onOfferClicked("two") }>
                <p className="membership-offer-title">Pack 2 tipsters</p>
                <p className="membership-offer-price">10€/mois</p>

                <p className="membership-offer-description">Grâce à cette offre, débloquez l'accès aux pronostics de 2 tipsters de votre choix.</p>
            </div>
        </Grid> */ }
        { /* <Grid item xs={12} sm={4} style={{ marginBottom: 30 }}>
            <div className="membership-offer-container" onClick={ () => onOfferClicked("one") }>
                <p className="membership-offer-title">Pack 1 tipster</p>
                <p className="membership-offer-price">5€/mois</p>

                <p className="membership-offer-description">Grâce à cette offre, débloquez l'accès aux pronostics d'1 tipster de votre choix.</p>
            </div>
        </Grid> */ }
    </>
}

export default withRouter(MembershipOffersForm);
