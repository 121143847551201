import './TipsterPreview.scss';
import { tipsterType } from "../../../types/tipster/TipsterType";
import {Link} from "react-router-dom";

const classNames = require('classnames');

const TipsterPreviewComponent = ({ name, picture, slug, twitterLink, disableHoverAnimation = false, onlyBorderRadiusLeft = false }) => {

    const tipsterPreviewCellClassNames = classNames({
        'tipster-preview-cell': true,
        'disabled-hover-animation': disableHoverAnimation,
        'no-borders-right': onlyBorderRadiusLeft,
    });

    return (
        disableHoverAnimation
            ? <div className={ tipsterPreviewCellClassNames }>
                <img src={ picture } alt={ name }/>
                <h3>{ name }</h3>
              </div>
            : <Link className={ tipsterPreviewCellClassNames } to={ `/tipster/${slug}` }>
                <img src={ picture } alt={ name }/>
                <h3>{ name }</h3>
              </Link>
    );
}

TipsterPreviewComponent.propTypes = {
    tipster: tipsterType
};

export default TipsterPreviewComponent;
