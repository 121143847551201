import './UserMyAccount.scss';
import CardComponent from "../../../components/card/Card";
import React from "react";
import {UserStore} from "../../../stores/UserStore";
import {Link} from "react-router-dom";

function UserMyAccount() {
    const user = UserStore.useState(store => store.user);

    const getMembershipStatus = (user) => {
        if (user?.subscriptions?.length > 0) {
            return (
                <span>{ getMembershipPlan(user.subscribedTipsters) }, actif { getFormattedMembershipEndDate(user.subscriptions[0]) }</span>
            );
        } else {
            return (
                <>
                    { /* <span className="buy-membership">Inactif</span> */ }
                    <span><Link to="/abonnement" className="buy-membership">Inactif, cliquez ici pour vous abonner.</Link></span>
                </>
            );
        }
    }

    const getMembershipPlan = (tipsters) => {
        switch (tipsters.length) {
            case 4:
                return `Pack ultimate`;
            default:
                return null;
        }
    }

    const getFormattedMembershipEndDate = ({ cancel_at_period_end: willNotRenew, start_date, current_period_end }) => {
        if (!willNotRenew) {
            const date = new Date(start_date * 1000);
            return `depuis le ${date.toLocaleDateString()}`;
        } else {
            const date = new Date(current_period_end * 1000);
            return `jusqu'au ${date.toLocaleDateString()}`;
        }
    }

    return (
        <div id="boxed-content">
            <CardComponent type="account">
                <h1 id="dashboard-title">Mon compte</h1>

                <h3>Informations</h3>

                <p className="mb-4px"><b>Nom :</b> { user?.lastName }</p>
                <p className="mb-4px"><b>Prénom :</b> { user?.firstName }</p>
                <p className="mb-4px"><b>Email :</b> { user?.email }</p>
                <p className="mb-4px"><b>Abonnement :</b> { getMembershipStatus(user) }</p>
            </CardComponent>
        </div>
    );
}

export default UserMyAccount;
