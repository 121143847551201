import ApiClient from "../api-client";
import { fb } from '../firebase';

export default class AuthenticationService {

    api = new ApiClient();

    LOCAL_STORAGE_TOKEN_KEY = 'token';

    login(email, password) {
        return fb.auth().signInWithEmailAndPassword(email, password)
            .then(async (user) => {
                const token = await user.user.getIdToken(false);
                this.api.user.storeToken(token);

                return {
                    hasError: false,
                }
            }).catch((error) => {
                let message;

                switch (error.code) {
                    case "auth/wrong-password":
                        message = `L'e-mail et/ou le mot de passe est invalide.`;
                        break;
                    default:
                        message = error.message;
                        break;
                }

                return {
                    hasError: true,
                    errorMessage: message,
                    user: null
                }
            });
    }

    async register(user) {
        try {
            await this.api.user.doRegister(user);

            await fb.auth().signInWithEmailAndPassword(user.email, user.password);

            return {
                hasError: false
            }
        } catch (error) {
            let message;

            switch (error.code) {
                case "auth/email-already-exists":
                    message = `Un compte avec cette adresse e-mail existe déjà.`;
                    break;
                case "auth/invalid-email":
                    message = `L'adresse e-mail est invalide.`;
                    break;
                case "auth/invalid-password":
                    message = `La complexité du mot de passe est trop faible.`;
                    break;
                default:
                    message = error.message;
                    break;
            }

            return {
                hasError: true,
                errorMessage: message,
                user: null
            }
        }
    }

    async logout() {
        this.deleteToken();
        await fb.auth().signOut();
    }

    storeToken(token) {
        localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, token);
    }

    getToken() {
        return localStorage.getItem(this.LOCAL_STORAGE_TOKEN_KEY);
    }

    deleteToken() {
        localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_KEY);
    }
}
