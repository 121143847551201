import React, { Component } from 'react';
import { fb } from '../../services/firebase';
import { NavLink, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { materialStyles } from "../../assets/styles/material-styling";
import { Role } from "../../models/role/RoleModel";
import { UserStore } from "../../stores/UserStore";
import './Header.scss';
import AuthenticationService from '../../services/authentication/AuthenticationService';
import logo from '../../assets/images/logo/logo.png';
import {
    BrowserView,
    MobileView,
    isMobile
} from "react-device-detect";
import AnimateHeight from "react-animate-height";
import { IconButton } from "@material-ui/core";
import { Close, Menu } from "@material-ui/icons";

const authenticationService = new AuthenticationService();

const useStyles = withStyles({
    root: materialStyles.headerSettingsButton
});

export default class HeaderComponent extends Component {

    state = {
        isAuthenticated: false,
        user: null,
        height: 0,
    }

    classes = useStyles;

    componentDidMount() {
        // get token
        if (authenticationService.getToken()) {
            this.setState({
                isAuthenticated: true
            });
        }

        this.subscribeToUserChange();
        this.subscribeToFbAuthChange();
    }

    subscribeToFbAuthChange() {
        fb.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    isAuthenticated: true,
                });
            } else {
                this.setState({
                    isAuthenticated: false,
                    user: null
                });
            }
        });
    }

    subscribeToUserChange() {
        UserStore.subscribe(store => store.user, fetchedUser => {
            this.setState({
                user: fetchedUser
            });
        });
    }

    getMiddleLinks(role) {
        switch (role) {
            case Role.REGISTERED:
            case Role.PREMIUM_MEMBER:
                return  <div id="middle">
                            <NavLink className="menu-link" to='/abonnement' onClick={ this.onLinkClicked }>Abonnement</NavLink>
                            <NavLink className="menu-link" to='/affiliation' onClick={ this.onLinkClicked }>Affiliation</NavLink>
                        </div>
            case Role.TIPSTER:
                return  <div id="middle">
                            <NavLink className="menu-link" to='/tipster' onClick={ this.onLinkClicked }>Espace Tipster</NavLink>
                            <NavLink className="menu-link" to='/affiliation' onClick={ this.onLinkClicked }>Affiliation</NavLink>
                        </div>
            case Role.TRANSLATOR:
                return  <div id="middle">
                            <NavLink className="menu-link" to='/traduction' onClick={ this.onLinkClicked }>Traduction</NavLink>
                        </div>
            case Role.ADMIN:
                return  <div id="middle">
                            <NavLink className="menu-link" to='/admin' onClick={ this.onLinkClicked }>Administration</NavLink>
                        </div>
            default:
                return <div id="middle">
                    <NavLink className="menu-link" to='/abonnement' onClick={ this.onLinkClicked }>Abonnement</NavLink>
                    <NavLink className="menu-link" to='/affiliation' onClick={ this.onLinkClicked }>Affiliation</NavLink>
                </div>
        }
    }

    onToggleHeight = () => {
        this.setState({
            height: (this.state.height === 0 ? 'auto' : 0),
        });
    }

    onLinkClicked = () => {
        if (isMobile && this.state.height === 'auto') {
            this.onToggleHeight();
        }
    }

    onLogOut = async () => {
        if (isMobile) {
            this.onToggleHeight();
        }

        await authenticationService.logout();
    }

    render() {
        return (
            <>
                <BrowserView>
                    <header>
                        <Link to='/'>
                            <img id="logo" src={ logo } alt="SportsBetNews Logo"/>
                        </Link>

                        {
                            this.getMiddleLinks(this.state.user?.role)
                        }

                        <div id="right-side">
                            {
                                this.state.isAuthenticated
                                    ? <>
                                        {
                                            this.state.user?.role === Role.TIPSTER
                                                ? <NavLink className="menu-link" to={`/tipster/${this.state.user.slug}`}>Mon
                                                    compte</NavLink>
                                                : <></>
                                        }
                                        {
                                            this.state.user?.role === Role.REGISTERED || this.state.user?.role === Role.PREMIUM_MEMBER
                                                ? <NavLink className="menu-link" to='/mon-compte'>Mon compte</NavLink>
                                                : <></>
                                        }
                                        <Link className="menu-link" to='/' onClick={ this.onLogOut }>Déconnexion</Link>
                                    </>
                                    : <>
                                        <NavLink className="menu-link" activeClassName='menu-link-active' to='/connexion'>Connexion</NavLink>
                                        <NavLink className="menu-link" activeClassName='menu-link-active' to='/inscription'>Inscription</NavLink>
                                    </>
                            }
                        </div>
                    </header>
                </BrowserView>

                <MobileView>
                    <header className="mobile-menu">
                        <div id="mobile-heading-container">
                            <Link to='/' onClick={ this.onLinkClicked }>
                                <img id="logo" src={ logo } alt="SportsBetNews Logo"/>
                            </Link>

                            <IconButton
                                aria-label="Dérouler le menu"
                                onClick={ this.onToggleHeight }
                                edge="end"
                            >
                                { this.state.height === 0 ? <Menu/> : <Close/> }
                            </IconButton>
                        </div>

                        <AnimateHeight height={ this.state.height } duration={ 300 } animateOpacity={ true }>
                            <div id="menu-links">
                                {
                                    this.state.isAuthenticated
                                        ? this.getMiddleLinks(this.state.user?.role)
                                        : <>
                                            <NavLink className="menu-link" to='/affiliation' onClick={ this.onLinkClicked }>Affiliation</NavLink>
                                        </>
                                }

                                {
                                    this.state.isAuthenticated
                                        ? <>
                                            { this.state.user?.role === Role.TIPSTER ? <NavLink className="menu-link" to={ `/tipster/${this.state.user.slug} `} onClick={ this.onLinkClicked }>Mon compte</NavLink> : <></> }
                                            { /* TODO: authorize this route again for all users */}
                                            { /*<NavLink className="menu-link" to='/mon-compte'>Mon compte</NavLink> */ }
                                            <Link className="menu-link" to='/' onClick={ this.onLogOut }>Déconnexion</Link>
                                        </>
                                        : <>
                                            <NavLink className="menu-link" activeClassName='menu-link-active' to='/connexion' onClick={ this.onLinkClicked }>Connexion</NavLink>
                                            <NavLink className="menu-link" activeClassName='menu-link-active' to='/inscription' onClick={ this.onLinkClicked }>Inscription</NavLink>
                                        </>
                                }
                            </div>
                        </AnimateHeight>
                    </header>
                </MobileView>
            </>
        );
    }
}
