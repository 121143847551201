import './StatisticPreview.scss';

const StatisticPreviewComponent = ({ bigText, label }) => {
    return (
        <div className="statistic-container">
            <p className="big-text">{ bigText }</p>
            <p className="label">{ label }</p>
        </div>
    )
}

export default StatisticPreviewComponent;
