import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Login.scss';
import CardComponent from "../../../components/card/Card";
import InputComponent from "../../../components/utils/input/Input";
import ButtonComponent from "../../../components/utils/button/Button";
import Alert from '@material-ui/lab/Alert';

import AuthenticationService from '../../../services/authentication/AuthenticationService';
import ApiClient from "../../../services/api-client";

const authenticationService = new AuthenticationService();

const DEFAULT_STATE = {
    email: '',
    password: '',
    isInvalidEmail: false,
    isInvalidPassword: false,
    errorMessage: null,
    isLoading: false
};

export default class LoginPage extends Component {

    state = { ...DEFAULT_STATE };
    api = new ApiClient();

    onSetEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    onSetPassword = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    onSubmitForm = async () => {
        if (this.state.isLoading) { return; }

        if (this.state.email !== '' && this.state.password !== '') {
            this.setLoading(true);
            this.clearFormErrors();

            const result = await authenticationService.login(this.state.email, this.state.password);

            if (result.hasError) {
                this.setState({
                    errorMessage: `L'email et/ou le mot de passe est invalide.`,
                    isLoading: false,
                });
            }
        } else {
            if (!this.state.email) {
                this.setState({
                    isInvalidEmail: true
                });
            }

            if (!this.state.password) {
                this.setState({
                    isInvalidPassword: true
                });
            }
        }
    }

    setLoading = (isLoading) => {
        this.setState({
            isLoading
        });
    }

    clearFormErrors = () => {
        this.setState({
            isInvalidEmail: false,
            isInvalidPassword: false,
            errorMessage: null
        });
    }

    render() {
        return (
            <CardComponent type="authentication">
                <h1>Connexion</h1>

                {
                    this.state.errorMessage
                        ? <Alert severity="error" style={{ marginBottom: '20px' }}>{ this.state.errorMessage }</Alert>
                        : <></>
                }

                <InputComponent
                    id="login__email-input"
                    label="Votre adresse email *"
                    isInvalid={ this.state.isInvalidEmail }
                    type="email"
                    onComplete={ this.onSetEmail }
                />

                <InputComponent
                    id="login__password-input"
                    label="Votre mot de passe *"
                    isInvalid={ this.state.isInvalidPassword }
                    type="password"
                    onComplete={ this.onSetPassword }
                />

                <Link className="authentication-link forgotten-password" to="/mot-de-passe-oublie">
                    Mot de passe oublié ?
                </Link>

                <ButtonComponent
                    id="authentication-submit-button"
                    label="Se connecter"
                    onClick={ this.onSubmitForm }
                    isLoading={ this.state.isLoading }/>
            </CardComponent>
        );
    }
}
