import './Affiliate.scss';
import CardComponent from "../../components/card/Card";
import zebetLogo from '../../assets/images/affiliation/logo-zebet.png';
import zebetBanner from '../../assets/images/affiliation/banner-zebet.jpeg';

const zebetAffiliateLink = process.env.REACT_APP_ZEBET_AFFILIATE_LINK;

export default function AffiliatePage() {
    return (
        <CardComponent type="affiliate">
            <h1>Affiliation</h1>

            <p>En vous <b>inscrivant</b> chez l'un des <b>bookmaker</b> suivant avec notre lien de parrainage, <b>obtenez jusqu'à 2 mois d'abonnement</b> sur <b>SportsBetNews</b> et <b>150€</b> chez le bookmaker choisi.</p>
            <p>Lorsque vous vous serez inscrit, contactez-nous en <b><a href="mailto:contact@sportsbetnews.fr">cliquant ici</a></b> en précisant votre <b>adresse email SportsBetNews</b> ainsi que le <b>pseudonyme</b> que vous avez utilisé pour vous inscrire chez le bookmaker.</p>

            <div className="tbn-aff-divider"/>

            <img id="zebet-logo" className="tbn-aff-logo" src={ zebetLogo } alt="Logo ZeBet"/>
            <p className="tbn-aff-description"><b>Gagnez 2 mois d'abonnement gratuits</b> et jusqu'à <b>150€ offerts</b> par <b>Zebet</b> avec notre code promo <b>TBN150</b> ou notre lien d'<b>affiliation</b> disponible en cliquant sur l'image ci-dessous.</p>
            <a className="tbn-aff-container" href={ zebetAffiliateLink } target="_blank" rel="noreferrer noopener">
                <img className="tbn-aff-image" src={ zebetBanner } alt="Bannière de parrainage ZeBet"/>
            </a>
        </CardComponent>
    )
}
