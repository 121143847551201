import './AdminDashboard.scss';
import CardComponent from "../../../components/card/Card";
import DashboardCellComponent from "../../../components/dashboard/cell/DashboardCell";
import React, {useEffect, useState} from "react";
import {UserStore} from "../../../stores/UserStore";
import ApiClient from "../../../services/api-client";
import AuthenticationService from "../../../services/authentication/AuthenticationService";
import {Skeleton} from "@material-ui/lab";
import SelectComponent from "../../../components/utils/select/Select";
import StatisticPreviewComponent from "../../../components/utils/statistic-preview/StatisticPreview";
import {getTipstersList} from '../../../helpers/utils';

const TIPSTERS_LIST_OPTIONS = [
    { label: "---", value: "" },
    ...getTipstersList().map(tipster => ({ label: tipster.name, value: tipster.slug })),
];

const AdminDashboardPage = () => {

    const api = new ApiClient();
    const authenticationService = new AuthenticationService();

    const user = UserStore.useState(store => store.user);

    const [ chosenTipster, setChosenTipster ] = useState("");
    const [ chosenTipsterStats, setChosenTipsterStats ] = useState(null);
    const [ isLoadingTipstersStats, setLoadingTipstersStats ] = useState(false);

    useEffect(() => {
        if (chosenTipster !== "") {
            setLoadingTipstersStats(true);

            api.setBearerAuth(authenticationService.getToken());
            api.admin.getStatsByTipster(chosenTipster)
                .then(result => setChosenTipsterStats(result))
                .catch(error => console.log(error))
                .finally(() => setLoadingTipstersStats(false))
        }
    }, [ chosenTipster ])

    return (
        <div id="boxed-content" className="aligned-left">
            <CardComponent type="admin-dashboard">
                <h1 id="dashboard-title">Bonjour { user?.firstName },</h1>

                <DashboardCellComponent
                    label="Liste des pronostics"
                    to="/admin/liste-pronostics"
                />

                <DashboardCellComponent
                    label="Publier un pronostic"
                    backgroundImage={ null }
                    to="/admin/nouveau-prono"
                />

                <DashboardCellComponent
                    label="Liste des utilisateurs"
                    to="/admin/liste-utilisateurs"
                />

                <h2 style={{ margin: '40px auto 0', textAlign: 'center' }}>Bilan par tipster:</h2>

                <div style={{ width: '100%', maxWidth: 250, margin: "auto" }}>
                    <SelectComponent id="admin-dashboard__tipstersList"
                                     label="Tipster *"
                                     value={ chosenTipster }
                                     options={ TIPSTERS_LIST_OPTIONS }
                                     onComplete={ event => setChosenTipster(event.target.value) }/>
                </div>

                {
                    chosenTipster !== ""
                        ? !isLoadingTipstersStats
                            ? chosenTipsterStats && <div id={ "admin-dashboard-chosen-tipster-stats-container" }>
                                <StatisticPreviewComponent bigText={ chosenTipsterStats.statistics.private.wonPrognoses }
                                                           label={ "pronos gagnés"}/>

                                <StatisticPreviewComponent bigText={ chosenTipsterStats.statistics.private.lostPrognoses }
                                                           label={ "pronos perdus"}/>

                                <StatisticPreviewComponent bigText={ chosenTipsterStats.statistics.successRate + "%" }
                                                           label={ "de réussite"}/>

                                <StatisticPreviewComponent bigText={ chosenTipsterStats.statistics.averageRating }
                                                           label={ "cote moy."}/>

                                <StatisticPreviewComponent bigText={ chosenTipsterStats.statistics.roi + "%" }
                                                           label={ "ROI"}/>

                                <StatisticPreviewComponent bigText={ chosenTipsterStats.statistics.roc + "%" }
                                                           label={ "ROC"}/>
                            </div>
                            : <Skeleton variant="rect"
                                        width={ '100%' }
                                        height={ 94 }
                            />
                    : <></>
                }
            </CardComponent>
        </div>
    )
}

export default AdminDashboardPage;
