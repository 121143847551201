import React, {useEffect, useState} from 'react';
import './MembershipPaymentForm.scss';
import {
    TextField,
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    OutlinedInput, CircularProgress
} from "@material-ui/core";
import {
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import { useStateValue } from "../membership-state-context";
import StripeInput from "../../../components/utils/stripe-input/StripeInput";
import { Search } from "@material-ui/icons";
import ApiClient from "../../../services/api-client";
import AuthenticationService from "../../../services/authentication/AuthenticationService";
import ModalComponent from "../../../components/utils/modal/Modal";

const MembershipPaymentForm = () => {

    const api = new ApiClient();
    const authenticationService = new AuthenticationService();

    const [{ formValues }, dispatch] = useStateValue();
    const [ offerId, setOfferId ] = useState('');
    const [ isRetrievingCoupon, setIsRetrievingCoupon ] = useState(false);

    const [ isModalOpen, setModalOpen ] = useState(false);
    const [ modalTitle, setModalTitle ] = useState('');
    const [ modalText, setModalText ] = useState('');

    useEffect(() => {
        switch (formValues.selected_offer) {
            case "one":
                updateOfferId(process.env.REACT_APP_MEMBERSHIP_OFFER_ONE_ID);
                break;
            case "two":
                updateOfferId(process.env.REACT_APP_MEMBERSHIP_OFFER_TWO_ID);
                break;
            case "three":
                updateOfferId(process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_ID);
                break;
            default:
                updateOfferId(process.env.REACT_APP_MEMBERSHIP_OFFER_ONE_ID);
                break;
        }
    }, [formValues.selected_offer]);

    const updateOfferId = (offer) => {
        setOfferId(offer);
        dispatch({
            type: 'editFormValue',
            key: "selected_offer_id",
            value: offer
        });
    }

    const onSearchCoupon = () => {
        if (formValues.coupon === "" || isRetrievingCoupon) { return; }

        api.setBearerAuth(authenticationService.getToken());
        setIsRetrievingCoupon(true);

        api.membership.checkCoupon(formValues.coupon, offerId)
            .then(({ isPercentage, discount, discountedPrice }) => {
                setModalTitle('Coupon valide !');

                if (isPercentage) {
                    setModalText(`Une réduction de ${discount}% a été appliquée. Le prix de votre abonnement est maintenant de ${discountedPrice}€.`)
                } else {
                    setModalText(`Une réduction de ${discount}€ a été appliquée. Le prix de votre abonnement est maintenant de ${discountedPrice}€.`)
                }
                setModalOpen(true);
            })
            .catch(error => {
                setModalTitle('Coupon invalide !');
                setModalText(error.message);
                setModalOpen(true);
            })
            .finally(() => setIsRetrievingCoupon(false));
    }

    return <>
        <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Paiement :</Typography>
            </Grid>
            <Grid container item xs={12} sm={9} justify="flex-end">
                {cardsLogo.map(e => <img key={e} src={`./assets/images/cards/${e}.png`} alt={e} width="50px" align="bottom" style={{ padding: "0 5px" }} />)}
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
            {
                formValues.selected_offer === "three"
                    ? <RadioGroup aria-label="billing" name="billing" value={ offerId } onChange={ (event) => updateOfferId(event.target.value) }>
                        <FormControlLabel value={ process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_ID } control={<Radio color="primary" />} label="Paiement mensuel de 15€/mois" />
                        <FormControlLabel value={ process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_YEARLY_ID } control={<Radio color="primary" />} label="Paiement annuel de 120€/an" />
                    </RadioGroup>
                    : <RadioGroup aria-label="billing" name="billing" value={ offerId } onChange={ (event) => setOfferId(event.target.value) }>
                        {
                            formValues.selected_offer === "one"
                             ? <FormControlLabel value={process.env.REACT_APP_MEMBERSHIP_OFFER_ONE_ID} control={<Radio color="primary"/>}
                                              label="Paiement mensuel de 5€/mois"/>
                             : <FormControlLabel value={process.env.REACT_APP_MEMBERSHIP_OFFER_TWO_ID} control={<Radio color="primary"/>}
                                                                  label="Paiement mensuel de 10€/mois"/>
                        }
                    </RadioGroup>
            }
        </Grid>
        <Grid item xs={12} sm={6}>
            {
                offerId === process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_YEARLY_ID || offerId === process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_ID
                    ? <FormControl variant="outlined" fullWidth={ true }>
                        <InputLabel htmlFor="coupon-field">Code promo</InputLabel>
                        <OutlinedInput
                            id="coupon-field"
                            value={ formValues.coupon }
                            onChange={e =>
                                dispatch({
                                    type: 'editFormValue',
                                    key: "coupon",
                                    value: e.target.value
                                })
                            }
                            onInput={(event) => {
                                    event.target.value = (event.target.value).toUpperCase();
                            }}
                            endAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="Appliquer le code promo"
                                        onClick={ onSearchCoupon }
                                        onMouseDown={ () => null }
                                        edge="end"
                                    >
                                        { isRetrievingCoupon ? <CircularProgress size={24}/> : <Search/> }
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={89}
                        />
                    </FormControl>
                    : <></>
            }
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Numéro de carte"
                name="ccnumber"
                variant="outlined"
                required
                fullWidth
                InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                        component: CardNumberElement
                    },
                }}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>
        <Grid item xs={6} sm={4}>
            <TextField
                label="Date d'expiration"
                name="ccexp"
                variant="outlined"
                required
                fullWidth
                InputProps={{
                    inputProps: {
                        component: CardExpiryElement
                    },
                    inputComponent: StripeInput
                }}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>
        <Grid item xs={6} sm={2}>
            <TextField
                label="CVC"
                name="cvc"
                variant="outlined"
                required
                fullWidth
                InputProps={{
                    inputProps: {
                        component: CardCvcElement
                    },
                    inputComponent: StripeInput
                }}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>

        <ModalComponent isOpen={ isModalOpen }
                        onClose={ () => setModalOpen(false) }>
            <h2 style={{ marginTop: 0 }}>{ modalTitle }</h2>
            <p>{ modalText }</p>
        </ModalComponent>
    </>
}

const cardsLogo = [
    "maestro",
    "mastercard",
    "visa",
    "visaelectron",
];

export default MembershipPaymentForm;
