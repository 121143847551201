import "./CookieBanner.scss";
import {useState} from "react";

const CookiesBannerComponent = () => {
    const LOCAL_STORAGE_ACCEPTED_COOKIE_KEYS = 'accepted-cookies';

    const [ accepted, setAccepted ] = useState(localStorage.getItem(LOCAL_STORAGE_ACCEPTED_COOKIE_KEYS) !== null && localStorage.getItem(LOCAL_STORAGE_ACCEPTED_COOKIE_KEYS) === 'true');

    const message = "Nous utilisons des cookies anonymisés afin d'améliorer votre expérience. En continuant de naviguer sur le site, vous acceptez notre utilisation de ces cookies.";

    const onAcceptCookies = () => {
        localStorage.setItem(LOCAL_STORAGE_ACCEPTED_COOKIE_KEYS, true);
        setAccepted(true);
    };

    return (
        !accepted
            ? <div id="cookie-banner-container">
                    <p>{message}</p>
                    <button id="cookie-banner-button" onClick={onAcceptCookies}>J'accepte</button>
            </div>
            : <></>
    )
}

export default CookiesBannerComponent;
