import React, { Component } from "react";
import { UserStore } from "../../../../stores/UserStore";
import { Court } from "../../../../models/court/CourtModel";
import './TipsterPublishPrognosis.scss';
import CardComponent from "../../../../components/card/Card";
import InputComponent from "../../../../components/utils/input/Input";
import ApiClient from "../../../../services/api-client";
import AuthenticationService from "../../../../services/authentication/AuthenticationService";
import SelectComponent from "../../../../components/utils/select/Select";
import DateTimeComponent from "../../../../components/utils/date-time/DateTime";
import ButtonComponent from "../../../../components/utils/button/Button";
import Alert from "@material-ui/lab/Alert";
import {TournamentType} from "../../../../models/tournament-type/TournamentTypeModel";

const DEFAULT_STATE = {
    tipster: null,
    prognosis_tournament: '',
    prognosis_tournamentType: '',
    prognosis_player_one: '',
    prognosis_player_two: '',
    prognosis_court: Court.GROUND,
    prognosis_start_date: new Date(),
    prognosis_prognosis: '',
    prognosis_analysis: '',
    prognosis_bookmaker: '',
    prognosis_category: 'other',
    prognosis_odds: '',
    prognosis_bankroll_bet: '',
    isTournamentInvalid: false,
    isTournamentTypeInvalid: false,
    isPlayerOneInvalid: false,
    isPlayerTwoInvalid: false,
    isCourtInvalid: false,
    isPrognosisInvalid: false,
    isAnalysisInvalid: false,
    isBookmakerInvalid: false,
    isCategoryInvalid: false,
    isOddsInvalid: false,
    isBankrollBetInvalid: false,
    isLoading: false,
    errorMessage: null
};

const TOURNAMENT_TYPES_VALUES = [
    { label: 'Football', value: TournamentType.FOOTBALL },
    { label: 'Rugby', value: TournamentType.RUGBY },
    { label: 'Tennis', value: TournamentType.TENNIS },
    { label: 'NBA', value: TournamentType.NBA },
    { label: 'NFL', value: TournamentType.NFL },
];

const COURT_VALUES = [
    { label: 'Dur', value: Court.GROUND },
    { label: 'Terre battue', value: Court.CLAY },
    { label: 'Gazon', value: Court.GRASS },
];

const BOOKMAKER_VALUES = [];

const CATEGORY_VALUES = [
    { label: 'Aces', value: 'aces' },
    { label: 'Autre', value: 'other' },
];

export default class TipsterPublishPrognosisPage extends Component {

    api;
    authenticationService = new AuthenticationService();

    state = { ...DEFAULT_STATE };

    componentDidMount() {
        this.api = new ApiClient();
        this.api.setBearerAuth(this.authenticationService.getToken());

        const userStoreState = UserStore.getRawState();
        this.setUser(userStoreState.user);

        UserStore.subscribe(store => store.user, fetchedUser => {
            this.setUser(fetchedUser);
        });
    }

    setUser = (user) => {
        if (user && BOOKMAKER_VALUES.length === 0) {
            user.bookmakerReferrals.forEach(bookmaker => {
                BOOKMAKER_VALUES.push({
                    label: bookmaker.name,
                    value: bookmaker
                });
            });
        }

        this.setState({ tipster: user });
    }

    onSubmitForm = async () => {
        if (this.isValidForm() && !this.state.isLoading) {
            this.setState({ isLoading: true, errorMessage: null });

            const tipster = {
                slug: this.state.tipster.nicknameSlug
            }

            const prognosis = {
                tournament: this.state.prognosis_tournament,
                tournamentType: this.state.prognosis_tournamentType,
                players: [ this.state.prognosis_player_one ],
                prognosis: this.state.prognosis_prognosis,
                analysis: this.state.prognosis_analysis,
                odds: Number(this.state.prognosis_odds),
                bankrollBet: Number(this.state.prognosis_bankroll_bet),
                category: this.state.prognosis_category,
                court: this.state.prognosis_court,
                startDate: this.state.prognosis_start_date.toISOString(),
                bookmaker: this.state.prognosis_bookmaker
            }

            if (this.state.prognosis_player_two !== '') {
                prognosis.players.push(this.state.prognosis_player_two);
            }

            try {
                await this.api.tipster.publishNewPrognosis(tipster, prognosis);
                this.props.history.push('/tipster');
            } catch (e) {
                this.setState({
                    isLoading: false,
                    errorMessage: e.message
                });
            }
        }
    }

    isValidForm() {
        const { prognosis_tournament, prognosis_tournamentType, prognosis_player_one, prognosis_court, prognosis_start_date, prognosis_prognosis, prognosis_analysis, prognosis_bookmaker, prognosis_odds, prognosis_bankroll_bet } = this.state;
        let isValid = true;

        if (prognosis_tournament === '') {
            this.setTournamentInvalid(true);
            isValid = false;
        } else {
            this.setTournamentInvalid(false);
        }

        if (prognosis_tournamentType === '') {
            this.setTournamentTypeInvalid(true);
            isValid = false;
        } else {
            this.setTournamentTypeInvalid(false);
        }

        if (prognosis_player_one === '') {
            this.setPlayerOneInvalid(true);
            isValid = false;
        } else {
            this.setPlayerOneInvalid(false);
        }

        if (prognosis_court === '') {
            this.setCourtInvalid(true);
            isValid = false;
        } else {
            this.setCourtInvalid(false);
        }

        if (!prognosis_start_date || prognosis_start_date.toString() === '' || prognosis_start_date.toString() === 'Invalid Date') {
            isValid = false;
        }

        if (prognosis_prognosis === '') {
            this.setPrognosisInvalid(true);
            isValid = false;
        } else {
            this.setPrognosisInvalid(false);
        }

        if (prognosis_analysis.length > process.env.REACT_APP_PROGNOSIS_ANALYSIS_CHARACTERS_LIMIT) {
            this.setAnalysisInvalid(true);
            isValid = false;
        } else {
            this.setAnalysisInvalid(false);
        }

        if (prognosis_bookmaker === '') {
            this.setBookmakerInvalid(true);
            isValid = false;
        } else {
            this.setBookmakerInvalid(false);
        }

        if (prognosis_odds === '' || isNaN(prognosis_odds)) {
            this.setOddsInvalid(true);
            isValid = false;
        } else {
            this.setOddsInvalid(false);
        }

        if (prognosis_bankroll_bet === '' || isNaN(prognosis_bankroll_bet)) {
            this.setBankrollBetInvalid(true);
            isValid = false;
        } else {
            this.setBankrollBetInvalid(false);
        }

        return isValid;
    }

    onSetTournament = (event) => { this.setState({ prognosis_tournament: event.target.value }); }
    onSetTournamentType = (event) => { this.setState({ prognosis_tournamentType: event.target.value }); }
    onSetPlayerOne = (event) => { this.setState({ prognosis_player_one: event.target.value }); }
    onSetPlayerTwo = (event) => { this.setState({ prognosis_player_two: event.target.value }); }
    onSetCourt = (event) => { this.setState({ prognosis_court: event.target.value }); }
    onSetStartDate = (event) => { this.setState({ prognosis_start_date: event }); }
    onSetPrognosis = (event) => { this.setState({ prognosis_prognosis: event.target.value }); }
    onSetAnalysis = (event) => { this.setState({ prognosis_analysis: event.target.value }); }
    onSetBookmaker = (event) => { this.setState({ prognosis_bookmaker: event.target.value }); }
    onSetCategory = (event) => { this.setState({ prognosis_category: event.target.value }); }
    onSetOdds = (event) => { this.setState({ prognosis_odds: event.target.value }); }
    onSetBankrollBet = (event) => { this.setState({ prognosis_bankroll_bet: event.target.value }); }

    setTournamentInvalid = (isInvalid) => this.setState({ isTournamentInvalid: isInvalid });
    setTournamentTypeInvalid = (isInvalid) => this.setState({ isTournamentTypeInvalid: isInvalid });
    setPlayerOneInvalid = (isInvalid) => this.setState({ isPlayerOneInvalid: isInvalid });
    setPlayerTwoInvalid = (isInvalid) => this.setState({ isPlayerTwoInvalid: isInvalid });
    setCourtInvalid = (isInvalid) => this.setState({ isCourtInvalid: isInvalid });
    setPrognosisInvalid = (isInvalid) => this.setState({ isPrognosisInvalid: isInvalid });
    setAnalysisInvalid = (isInvalid) => this.setState({ isAnalysisInvalid: isInvalid });
    setBookmakerInvalid = (isInvalid) => this.setState({ isBookmakerInvalid: isInvalid });
    setCategoryInvalid = (isInvalid) => this.setState({ isCategoryInvalid: isInvalid });
    setOddsInvalid = (isInvalid) => this.setState({ isOddsInvalid: isInvalid });
    setBankrollBetInvalid = (isInvalid) => this.setState({ isBankrollBetInvalid: isInvalid });

    render() {
        return (
            <div id="boxed-content">
                <CardComponent type="dashboard">
                    <h1 id="dashboard-title">Publier un pronostic</h1>

                    {
                        this.state.errorMessage
                            ? <Alert severity="error" style={{ marginBottom: '20px' }}>{ this.state.errorMessage }</Alert>
                            : <></>
                    }

                    <div id="tournament-info" className="half-field mb-10px">
                        <SelectComponent id="tipster-publish__tournamentType"
                                         label="Sport *"
                                         value={ this.state.prognosis_tournamentType }
                                         options={ TOURNAMENT_TYPES_VALUES }
                                         isInvalid={ this.state.isTournamentTypeInvalid }
                                         onComplete={ this.onSetTournamentType }/>

                        <InputComponent
                            id="tipster-publish__tournament"
                            label="Nom du tournoi *"
                            isInvalid={ this.state.isTournamentInvalid }
                            onComplete={ this.onSetTournament }
                        />
                    </div>

                    <div id="players-info" className="half-field mb-10px">
                        <InputComponent
                            id="tipster-publish__player-one"
                            label="Joueur 1 ou tournoi *"
                            isInvalid={ this.state.isPlayerOneInvalid }
                            onComplete={ this.onSetPlayerOne }
                        />

                        <InputComponent
                            id="tipster-publish__player-two"
                            label="Joueur 2 (facultatif)"
                            onComplete={ this.onSetPlayerTwo }
                        />
                    </div>

                    <div id="match-info" className="mb-30px">
                         <DateTimeComponent
                                        label="Date de début *"
                                        value={ this.state.prognosis_start_date }
                                        format={ "dd/MM/yyyy" }
                                        onComplete={ this.onSetStartDate }/>
                    </div>

                    <InputComponent
                        id="tipster-publish__prognosis"
                        label="Mon pronostic *"
                        isInvalid={ this.state.isPrognosisInvalid }
                        onComplete={ this.onSetPrognosis }
                        hasMarginBottom={ true }
                    />

                    <InputComponent
                        id="tipster-publish__analysis"
                        label="Mon analyse (facultatif)"
                        isInvalid={ this.state.isAnalysisInvalid }
                        invalidText={ "L'analyse ne doit pas dépasser 400 caractères." }
                        onComplete={ this.onSetAnalysis }
                        hasMarginBottom={ true }
                    />

                    <div id="betting-info" className="half-field mb-10px">
                        <SelectComponent id="tipster-publish__bookmaker"
                                         label="Bookmaker *"
                                         value={ this.state.prognosis_bookmaker }
                                         options={ BOOKMAKER_VALUES }
                                         isInvalid={ this.state.isBookmakerInvalid }
                                         onComplete={ this.onSetBookmaker }/>

                        <div className="half-field">
                            <InputComponent
                                id="tipster-publish__odds"
                                label="Cote *"
                                type="number"
                                isInvalid={ this.state.isOddsInvalid }
                                invalidText={ "La cote doit être un nombre." }
                                onComplete={ this.onSetOdds }
                            />

                            <InputComponent
                                id="tipster-publish__bk-bet"
                                label="% Mise BK *"
                                type="number"
                                isInvalid={ this.state.isBankrollBetInvalid }
                                invalidText={ "La mise doit être un nombre." }
                                onComplete={ this.onSetBankrollBet }
                            />
                        </div>
                    </div>

                    <ButtonComponent
                        id="tipster-publish-prognosis-submit-button"
                        label="Publier le prono"
                        onClick={ this.onSubmitForm }
                        isLoading={ this.state.isLoading }
                        style={{ marginTop: 30}} />
                </CardComponent>
            </div>
        );
    }
}
