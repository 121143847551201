import './AdminUsersViewDetails.scss';
import React, {useEffect, useState} from "react";
import CardComponent from "../../../../../components/card/Card";
import ApiClient from "../../../../../services/api-client";
import AuthenticationService from "../../../../../services/authentication/AuthenticationService";
import ModalComponent from "../../../../../components/utils/modal/Modal";
import SelectComponent from "../../../../../components/utils/select/Select";
import ButtonComponent from "../../../../../components/utils/button/Button";
import InputComponent from "../../../../../components/utils/input/Input";
import {getTipstersNames} from "../../../../../helpers/utils";

const MEMBERSHIP_OFFER_VALUES = [
    { label: 'Pack ultimate mensuel', value: process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_ID },
    { label: 'Pack ultimate annuel', value: process.env.REACT_APP_MEMBERSHIP_OFFER_THREE_YEARLY_ID }
];

const AdminUsersViewDetails = (props) => {
    const api = new ApiClient();
    const authenticationService = new AuthenticationService();

    const [ userId, setEmailParams ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    const [ isModalOpen, setModalOpen ] = useState(false);
    const [ isInvalidMembershipOffer, setInvalidMembershipOffer ] = useState(false);
    const [ chosenMembership, setChosenMembership ] = useState('');
    const [ canShowNumberOfDaysInput, setCanShowNumberOfDaysInput ] = useState(false);
    const [ numberOfDaysToOffer, setNumberOfDaysToOffer ] = useState(0);
    const [ isInvalidNumberOfDaysToOffer, setInvalidNumberOfDaysToOffer ] = useState(false);
    const [ isSettingMembership, setSettingMembership ] = useState(false);

    useEffect(() => {
        const emailParams = props.match.params.email;
        setEmailParams(emailParams);

        api.setBearerAuth(authenticationService.getToken());

        api.admin.getUserByEmail(emailParams)
            .then(user => {
                setUser(user);
                setLoading(false);
            }).catch(error => {
                console.log(error);
            });
    }, [ userId ]);

    const onSelectMembershipToGive = (event) => {
        const value = event.target.value;
        setCanShowNumberOfDaysInput(false);
        setChosenMembership(value);

        if (value === MEMBERSHIP_OFFER_VALUES[0].value) { // MONTHLY
            setNumberOfDaysToOffer(30);
        } else if (value === MEMBERSHIP_OFFER_VALUES[1].value) { // YEARLY
            setNumberOfDaysToOffer(365);
        } else {
            setNumberOfDaysToOffer(0);
        }

        setTimeout(() => {
            setCanShowNumberOfDaysInput(true);
        });
    }

    const onConfirmToGiveFreeMembership = () => {
        if (isSettingMembership) {
            return;
        }

        const nbrOfDaysInNumber = Number(numberOfDaysToOffer);

        if (chosenMembership !== '' && (nbrOfDaysInNumber > 0 && !isNaN(nbrOfDaysInNumber) && nbrOfDaysInNumber !== '')) {
            setInvalidMembershipOffer(false);
            setInvalidNumberOfDaysToOffer(false);
            setSettingMembership(true);

            const subscribedTipsters = getTipstersNames();

            api.setBearerAuth(authenticationService.getToken());
            api.admin.giveFreeMembership(user, subscribedTipsters, chosenMembership, numberOfDaysToOffer)
                .then(result => {
                    setModalOpen(false);
                    setLoading(true);
                    setSettingMembership(false);

                    api.admin.getUserByEmail(userId)
                        .then(user => {
                            setUser(user);
                            setLoading(false);
                        }).catch(error => {
                            console.log(error);
                            setLoading(false);
                        });
                }).catch(error => {
                    console.log(error);
                    setLoading(false);
                })
        } else {
            if (chosenMembership === '') {
                setInvalidMembershipOffer(true);
            }

            if (nbrOfDaysInNumber === 0 || isNaN(nbrOfDaysInNumber) || nbrOfDaysInNumber === '') {
                setInvalidNumberOfDaysToOffer(true);
            }
        }
    }

    const getHumanReadableRole = (role) => {
        switch (role) {
            case 10:
                return 'Administrateur';
            case 4:
                return 'Traducteur';
            case 3:
                return 'Tipster';
            case 2:
                return 'Abonné';
            default:
                return 'Non abonné';
        }
    }

    const getFormattedMembershipEndDate = ({ cancel_at_period_end: willNotRenew, start_date, current_period_end }) => {
        if (!willNotRenew) {
            const date = new Date(start_date * 1000);
            return `depuis le ${date.toLocaleDateString()}`;
        } else {
            const date = new Date(current_period_end * 1000);
            return `jusqu'au ${date.toLocaleDateString()}`;
        }
    }

    return (
        <div id="boxed-content" className="aligned-left">
            <CardComponent type="admin-dashboard">
                <h1 id="dashboard-title">Détails d'un utilisateur</h1>

                {
                    loading
                        ? <>Chargement...</>
                        : <>
                            <h2>Informations</h2>

                            <p><b>Id :</b> { user.id }</p>
                            <p><b>Email :</b> { user.email }</p>
                            <p><b>Prénom :</b> { user.firstName }</p>
                            <p><b>Nom :</b> { user.lastName }</p>
                            <p><b>Rôle :</b> { getHumanReadableRole(user.role) }</p>


                            <div className="tbn-aff-divider"/>

                            <h2 className="user-info-mt-50px">Abonnement</h2>

                            <p><b>Stripe Id :</b> { user.stripeCustomerId }</p>
                            <p><b>Statut de l'abonnement : </b>
                                {
                                    user.role === 2
                                        ? `Actif ${getFormattedMembershipEndDate(user.subscriptions[0])}`
                                        : "Inactif"
                                }
                                {
                                    user.role === 1
                                        ? <span className="authentication-link admin-offer-membership"
                                                onClick={ () => setModalOpen(true) }>
                                                    Offrir un abonnement
                                        </span>
                                        : <></>
                                }
                            </p>
                            { user.role === 2 ? <p><b>Tipsters : </b> { user.subscribedTipsters.join(', ') }</p> : <></> }

                            <div className="tbn-aff-divider"/>

                            <h2 className="user-info-mt-50px">Informations complémentaires</h2>

                            <p><b>Langue :</b> { user.language }</p>
                            <p><b>Compte créé le :</b> { new Date(user.createdAt).toLocaleDateString() }</p>
                        </>
                }
            </CardComponent>

            <ModalComponent isOpen={ isModalOpen }
                            onClose={ () => setModalOpen(false) }>
                <h2 style={{ marginTop: 0 }}>Offrir un abonnement à { user?.firstName } { user?.lastName.toUpperCase() }</h2>

                <SelectComponent id="admin_membership-title"
                                 label="Abonnement *"
                                 value={ chosenMembership }
                                 options={ MEMBERSHIP_OFFER_VALUES }
                                 isInvalid={ isInvalidMembershipOffer }
                                 invalidText="Vous devez choisir un abonnement."
                                 onComplete={ onSelectMembershipToGive }/>

                {
                    canShowNumberOfDaysInput
                        ? <InputComponent
                            id="admin_membership-days"
                            label="Nombre de jours *"
                            type="number"
                            defaultValue={ numberOfDaysToOffer }
                            isInvalid={ isInvalidNumberOfDaysToOffer }
                            invalidText={ "Vous devez spécifier au moins 1 jour." }
                            onComplete={ event => setNumberOfDaysToOffer(event.target.value) }
                        />
                        : <></>
                }
                <ButtonComponent
                    id="admin_membership-submit-button"
                    label="Confirmer"
                    onClick={ onConfirmToGiveFreeMembership }
                    isLoading={ isSettingMembership }
                    style={{ marginTop: 30}} />
            </ModalComponent>
        </div>
    )
}

export default AdminUsersViewDetails;
