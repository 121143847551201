import React, { Component } from 'react';
import './Register.scss';
import AuthenticationService from '../../../services/authentication/AuthenticationService';
import Alert from "@material-ui/lab/Alert";
import CardComponent from "../../../components/card/Card";
import InputComponent from "../../../components/utils/input/Input";
import ButtonComponent from "../../../components/utils/button/Button";
import ApiClient from "../../../services/api-client";
import SelectComponent from "../../../components/utils/select/Select";

const authenticationService = new AuthenticationService();

const DEFAULT_STATE = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    language: 'FR',
    isEmailInvalid: false,
    isFirstNameInvalid: false,
    isLastNameInvalid: false,
    isPasswordInvalid: false,
    isPasswordConfirmInvalid: false,
    isPasswordConfirmMatchingPassword: true,
    isLanguageInvalid: false,
    errorMessage: null,
    isLoading: false
};

const LANGUAGE_VALUES = [
    { label: 'Français', value: 'FR' },
    { label: 'Anglais', value: 'EN' },
];

export default class RegisterPage extends Component {

    state = { ...DEFAULT_STATE };
    api = new ApiClient();

    onSubmitForm = async () => {
        if (this.state.isLoading) { return; }

        if (this.isFormValid()) {
            const { email, firstName, lastName, password, language } = this.state;

            this.setLoading(true);
            this.clearFormErrors();

            await this.doRegister({ email, firstName, lastName, password, language });
        }
    }

    doRegister = async (user) => {
        const result = await authenticationService.register(user);

        if (result.hasError) {
            this.setState({
                errorMessage: result.errorMessage,
                isLoading: false,
            });
        }
    }

    isFormValid = () => {
        const { email, firstName, lastName, password, passwordConfirm } = this.state;
        let isValid = true;

        if (email === '') {
            this.setEmailInvalid(true);
            isValid = false;
        } else {
            this.setEmailInvalid(false);
        }

        if (firstName === '') {
            this.setFirstNameInvalid(true);
            isValid = false;
        } else {
            this.setFirstNameInvalid(false);
        }

        if (lastName === '') {
            this.setLastNameInvalid(true);
            isValid = false;
        } else {
            this.setLastNameInvalid(false);
        }

        if (password === '') {
            this.setPasswordInvalid(true);
            isValid = false;
        } else {
            this.setPasswordInvalid(false);
        }

        if (passwordConfirm === '') {
            this.setPasswordConfirmInvalid(true);
            isValid = false;
        } else {
            this.setPasswordConfirmInvalid(false);
        }

        if (password !== passwordConfirm) {
            this.setPasswordInvalid(true);
            this.setPasswordConfirmInvalid(true);
            this.setPasswordConfirmMatchingPasswordInvalid(true);
            isValid = false;
        } else {
            this.setPasswordConfirmMatchingPasswordInvalid(false);
        }

        return isValid;
    }

    onSetFirstName = (event) => {
        this.setState({
            firstName: event.target.value
        });
    }

    onSetLastName = (event) => {
        this.setState({
            lastName: event.target.value
        });
    }

    onSetEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    onSetPassword = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    onSetPasswordConfirm = (event) => {
        this.setState({
            passwordConfirm: event.target.value
        });
    }

    onSetLanguage = (event) => {
        this.setState({
            language: event.target.value
        });
    }

    setEmailInvalid = (isInvalid) => this.setState({ isEmailInvalid: isInvalid });
    setFirstNameInvalid = (isInvalid) => this.setState({ isFirstNameInvalid: isInvalid });
    setLastNameInvalid = (isInvalid) => this.setState({ isLastNameInvalid: isInvalid });
    setPasswordInvalid = (isInvalid) => this.setState({ isPasswordInvalid: isInvalid });
    setPasswordConfirmInvalid = (isInvalid) => this.setState({ isPasswordConfirmInvalid: isInvalid });
    setPasswordConfirmMatchingPasswordInvalid = (isInvalid) => this.setState({ isPasswordConfirmMatchingPassword: !isInvalid });

    setLoading = (isLoading) => {
        this.setState({
            isLoading
        });
    }

    clearFormErrors = () => {
        this.setState({
            errorMessage: null
        });
    }

    render() {
        return (
            <CardComponent type="authentication">
                <h1>Inscription</h1>

                {
                    this.state.errorMessage
                        ? <Alert severity="error" style={{ marginBottom: '20px' }}>{ this.state.errorMessage }</Alert>
                        : <></>
                }

                <div className="half-field">
                    <InputComponent
                        id="register__firstname-input"
                        label="Votre prénom *"
                        isInvalid={ this.state.isFirstNameInvalid }
                        type="text"
                        onComplete={ this.onSetFirstName }
                    />

                    <InputComponent
                        id="register__lastname-input"
                        label="Votre nom *"
                        isInvalid={ this.state.isLastNameInvalid }
                        type="email"
                        onComplete={ this.onSetLastName }
                    />
                </div>

                <InputComponent
                    id="register__email-input"
                    label="Votre adresse email *"
                    isInvalid={ this.state.isEmailInvalid }
                    type="email"
                    onComplete={ this.onSetEmail }
                />

                <InputComponent
                    id="register__password-input"
                    label="Votre mot de passe *"
                    isInvalid={ this.state.isPasswordConfirmInvalid }
                    invalidText={ this.state.isPasswordConfirmMatchingPassword ? 'Ce champs est requis.' : 'Les deux mots de passe doivent correspondre.' }
                    type="password"
                    onComplete={ this.onSetPassword }
                />

                <InputComponent
                    id="register__confirm-password-input"
                    label="Confirmer votre mot de passe *"
                    isInvalid={ this.state.isPasswordConfirmInvalid }
                    invalidText={ this.state.isPasswordConfirmMatchingPassword ? 'Ce champs est requis.' : 'Les deux mots de passe doivent correspondre.' }
                    type="password"
                    onComplete={ this.onSetPasswordConfirm }
                />

                <SelectComponent id="register__language"
                                 label="Langue *"
                                 value={ this.state.language }
                                 options={ LANGUAGE_VALUES }
                                 isInvalid={ this.state.isLanguageInvalid }
                                 onComplete={ this.onSetLanguage }/>

                <p className="authentication-description-text">En vous inscrivant, vous certifiez avoir lu et accepté nos <a href="/conditions-generales" target="_blank" rel='noreferrer noopener'>conditions générales</a> et vous certifiez être agé de plus de 18 ans.</p>

                <ButtonComponent
                    id="authentication-submit-button"
                    label="S'inscrire"
                    onClick={ this.onSubmitForm }
                    isLoading={ this.state.isLoading }/>
            </CardComponent>
        );
    }
}
