import './NotFound.scss';
import { Link } from "react-router-dom";
import CardComponent from "../../components/card/Card";
import ButtonComponent from "../../components/utils/button/Button";

function NotFoundPage() {
    return (
        <CardComponent type={ "error" }>
            <h1>Erreur</h1>

            <p style={{marginBottom: '30px'}}>Désolé la page que vous cherchez n'existe pas !</p>

            <Link to="/" style={{textDecoration: 'none'}}>
                <ButtonComponent isLoading={false}
                                 label={`Revenir à la page d'accueil`}
                                 onClick={() => null}
                />
            </Link>
        </CardComponent>
    )
}

export default NotFoundPage;
