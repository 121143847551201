export const Bookmaker = {
    BETCLIC: 'Betclic',
    UNIBET: 'Unibet',
    ZEBET: 'ZeBet',
    WINAMAX: 'Winamax',
    FDJ: 'FDJ',
    PMU: 'PMU',
    POKERSTARS: 'PokerStars',
    VBET: 'VBET',
}
