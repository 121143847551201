import React, { Component } from 'react';
import './LoadingHome.scss';
import { Skeleton } from "@material-ui/lab";
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import TelegramBannerComponent from "../../../components/telegram-banner/TelegramBanner";
import {getTipstersList} from "../../../helpers/utils";

export default class LoadingHomePage extends Component {

    globalStatsList = new Array(1).fill(undefined);
    tipstersList = new Array(getTipstersList().length).fill(undefined);
    prognosisList = new Array(10).fill(undefined);

    render() {
        return (
            <>
                <TelegramBannerComponent/>

                <div id="website-tagline">
                    <h2>
                        L'expertise de la Team SBN au service des parieurs<br/>
                        PASSION - EXPERIENCE - RIGUEUR
                    </h2>
                </div>

                <div id="boxed-content">

                    <h2 style={{ margin: '0 auto 20px', textAlign: 'center' }}>Notre bilan global:</h2>
                    <div id="global-stats-wrapper" className="loading" style={{ borderRadius: 8 }}>
                        {
                            this.globalStatsList.map(() =>
                                <Skeleton key={ uuidv4() }
                                          variant="rect"
                                          width={ '100%' }
                                          height={ 94 }
                                />
                            )
                        }
                    </div>

                    <h2 style={{ margin: '50px auto 0', textAlign: 'center' }}>Filtrer par tipster:</h2>
                    { /*<h2 style={{ margin: '0 auto', textAlign: 'center' }}>Filtrer par tipster:</h2> */}
                    <span style={{ margin: '0 auto 20px', textAlign: 'center' }}>(permet aussi de voir le bilan)</span>

                    <div id={"tipsters-preview-list"}>
                        {
                            this.tipstersList.map(() =>
                                <Skeleton key={ uuidv4() }
                                          variant="rect"
                                          width={ isMobile ? '100%' : 192 }
                                          height={ isMobile ? 90 : 166 }
                                />
                            )
                        }
                    </div>

                    <h2 style={{ margin: '50px auto 20px', textAlign: "center" }}>Tous nos pronostics en cours:</h2>

                    <div id="prognoses-preview-list">
                        {
                            this.prognosisList.map(() =>
                                <Skeleton key={ uuidv4() } variant="rect" width="100%" height={166}/>
                            )
                        }
                    </div>
                </div>
            </>
        );
    }
};
