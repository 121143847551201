import './MyAccount.scss';
import { UserStore } from "../../stores/UserStore";
import { Role } from "../../models/role/RoleModel";
import TipsterMyAccount from "./tipster/TipsterMyAccount";
import UserMyAccount from "./user/UserMyAccount";

function getContentComponent(role) {
    switch (role) {
        case Role.ADMIN:
        case Role.TIPSTER:
            return <TipsterMyAccount/>
        default:
            return <UserMyAccount/>
    }
}

function MyAccountPage() {

    const user = UserStore.useState().user;

    return (
        <>
            {
                getContentComponent(user?.role)
            }
        </>
    )
}

export default MyAccountPage;
