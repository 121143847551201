import PropTypes from "prop-types";

export const prognosisType = PropTypes.exact({
    docId: PropTypes.string,
    id: PropTypes.string.isRequired,
    hasAccess: PropTypes.bool,
    category: PropTypes.string,
    tipsterSlug: PropTypes.string.isRequired,
    tournament: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(null),
    ]),
    tournamentType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(null),
    ]),
    players: PropTypes.arrayOf(PropTypes.string).isRequired,
    prognosis: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.instanceOf(null).isRequired,
    ]),
    analysis: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.instanceOf(null).isRequired,
    ]),
    odds: PropTypes.number.isRequired,
    bankrollBet: PropTypes.number.isRequired,
    court: PropTypes.string.isRequired,
    hasBeenValidated: PropTypes.bool.isRequired,
    finalType: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    isPublic: PropTypes.bool,
    publishDate: PropTypes.string.isRequired,
    bookmaker: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
    }),
});
