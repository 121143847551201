import React from 'react';
import PropTypes from "prop-types";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DateTimeComponent = ({ label, value, format="dd/MM/yyyy HH:mm", disablePast = true, onComplete }) => {

    return (
        <>
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <KeyboardDateTimePicker
                    fullWidth={ true }
                    variant="inline"
                    ampm={ false }
                    label={ label }
                    value={ value }
                    onChange={ onComplete }
                    disablePast={ disablePast }
                    format={ format }
                    invalidDateMessage="La date est invalide."
                />
            </MuiPickersUtilsProvider>
        </>
    );
};

DateTimeComponent.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date),
    onComplete: PropTypes.func.isRequired,
};

export default DateTimeComponent;
