import './Footer.scss';
import { Facebook, Instagram, Mail, Twitter, YouTube } from "@material-ui/icons";
import { Link } from "react-router-dom";

const FooterComponent = () => {
    return (
        <footer>
            <div>
                <h2 id="website-title">SportsBetNews</h2>
                <p id="website-credits">par TennisBreakNews</p>
            </div>

            <div id="footer-middle-links-container">
                <Link to="/conditions-generales">Conditions générales</Link>
            </div>

            <div>
                <h3 id="social-networks-title">Contactez-nous :</h3>
                <div id="social-networks-container">
                    <a className="social-network-link" href={ `mailto:${process.env.REACT_APP_CONTACT_EMAIL}` } >
                        <Mail/>
                    </a>

                    <a className="social-network-link" href={ process.env.REACT_APP_SOCIAL_NETWORK_FACEBOOK_URL } target="_blank" rel='noreferrer noopener'>
                        <Facebook/>
                    </a>

                    <a className="social-network-link" href={ process.env.REACT_APP_SOCIAL_NETWORK_TWITTER_URL } target="_blank" rel='noreferrer noopener'>
                        <Twitter/>
                    </a>

                    <a className="social-network-link" href={ process.env.REACT_APP_SOCIAL_NETWORK_INSTAGRAM_URL } target="_blank" rel='noreferrer noopener'>
                        <Instagram/>
                    </a>

                    <a className="social-network-link" href={ process.env.REACT_APP_SOCIAL_NETWORK_YOUTUBE_URL } target="_blank" rel='noreferrer noopener'>
                        <YouTube/>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
