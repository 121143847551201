import React from 'react';
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { materialStyles } from "../../../assets/styles/material-styling";

const useStyles = makeStyles({
    root: materialStyles.textField
});

const InputComponent = ({ id, label, defaultValue = '', type = 'text', isInvalid = false, invalidText = 'Ce champs est requis.',
                            onComplete, shouldTransformToUpperCase = false, hasMarginBottom = false }) => {

    const classes = useStyles();

    const transformToUpperCase = (event) => {
        if (shouldTransformToUpperCase) {
            event.target.value = (event.target.value).toUpperCase();
        }
    }

    return (
        <>
            <TextField
                id={ id }
                label={ label }
                defaultValue={ defaultValue }
                type={ type }
                error={ isInvalid }
                helperText={ isInvalid ? invalidText : " " }
                className={ classes.root }
                InputProps={{
                    classes: {
                        input: classes.root
                    }
                }}
                onInput={ transformToUpperCase }
                onBlur={ onComplete }
                onChange={ onComplete }
                fullWidth={ true }
                style={ hasMarginBottom ? { 'marginBottom': '10px' } : null }
            />
        </>
    );
};

InputComponent.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    isInvalid: PropTypes.bool,
    invalidText: PropTypes.string,
    onComplete: PropTypes.func.isRequired,
    hasMarginBottom: PropTypes.bool
};

export default InputComponent;
