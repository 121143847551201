import './AdminPrognosesList.scss';
import ApiClient from "../../../../services/api-client";
import AuthenticationService from "../../../../services/authentication/AuthenticationService";
import React, {useEffect, useState} from "react";
import CardComponent from "../../../../components/card/Card";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";

const AdminPrognosesList = () => {
    const api = new ApiClient();
    const authenticationService = new AuthenticationService();

    const [ prognosesList, setPrognosesList ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        api.setBearerAuth(authenticationService.getToken());

        api.admin.getAllPrognoses()
            .then(prognoses => {
                setPrognosesList(prognoses);
                setLoading(false);
            }).catch(error => {
            console.log(error);
        });
    }, []);

    const formatStartDate = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            hour12: false,
            minute: '2-digit'
        });
    }

    return (
        <div id="boxed-content" className="aligned-left">
            <CardComponent type="admin-dashboard-table">
                <h1 id="dashboard-title">Liste des pronostics</h1>

                {
                    loading
                        ? <>Chargement...</>
                        : <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Match</TableCell>
                                        <TableCell align="left">Tipster</TableCell>
                                        <TableCell align="left">Public</TableCell>
                                        <TableCell align="left">Date de début</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prognosesList.map((prognosis) => (
                                        <TableRow component={ Link } to={ `/admin/pronostic/${prognosis.docId}` } className="user-table-row" key={prognosis.docId}>
                                            <TableCell align="left">{prognosis.players[0]}{ prognosis.players.length > 1 ? " vs " + prognosis.players[1] : ''}</TableCell>
                                            <TableCell align="left">{prognosis.tipsterSlug.toTitleCase()}</TableCell>
                                            <TableCell align="left">{prognosis.isPublic ? "Oui" : "Non"}</TableCell>
                                            <TableCell align="left">{formatStartDate(prognosis.startDate)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </CardComponent>
        </div>
    )
}

export default AdminPrognosesList;
