import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { materialStyles } from "../../../assets/styles/material-styling";
import './Button.scss';

const useStyles = makeStyles({
    root: materialStyles.button
});

const ButtonComponent = ({ id, label, onClick, isLoading, style }) => {
    const classes = useStyles();

    return (
        <Button
            id={ id }
            variant="contained"
            color="primary"
            className={ classes.root }
            onClick={ onClick }
            style={ style }
            disableElevation>
            { isLoading ? <CircularProgress size="28px" /> : label }
        </Button>
    )
}

ButtonComponent.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    style: PropTypes.object,
};

export default ButtonComponent;
