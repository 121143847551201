import React from "react";
import './ScrollToTop.scss';
import { KeyboardArrowUp } from "@material-ui/icons";

const ScrollToTopComponent = () => {
    const smoothlyScrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
        <div id="scroll-to-top-container" onClick={ smoothlyScrollToTop }>
            <KeyboardArrowUp/>
        </div>
    )
}

export default ScrollToTopComponent;
