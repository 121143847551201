import React, { Component } from "react";
import './TipsterPrognosesList.scss';
import CardComponent from "../../../../components/card/Card";
import AuthenticationService from "../../../../services/authentication/AuthenticationService";
import ApiClient from "../../../../services/api-client";
import {Skeleton} from "@material-ui/lab";
import PrognosisPreviewComponent from "../../../../components/prognosis/prognosis-preview/PrognosisPreview";

export default class TipsterPrognosesListPage extends Component {

    api;
    authenticationService = new AuthenticationService();

    fakePrognosesList = new Array(20).fill(undefined);

    state = {
        prognoses: [],
        isLoading: true,
    }

    componentDidMount() {
        this.api = new ApiClient();
        this.api.setBearerAuth(this.authenticationService.getToken());

        this.api.tipster.getLastTwentyPublishedPrognoses()
            .then(list => {
                this.setState({ prognoses: list, isLoading: false });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div id="boxed-content">
                <CardComponent type="dashboard">
                    <h1 id="dashboard-title">Mes derniers pronostics</h1>

                    {
                        this.state.isLoading
                            ? this.fakePrognosesList.map((prognosis, index) => <Skeleton key={ index }
                                                                                         variant="rect"
                                                                                         width="100%"
                                                                                         height={166}/>)
                            : this.state.prognoses.map(prognosis =>
                                <PrognosisPreviewComponent
                                    key={ prognosis.prognosis.id }
                                    tipster={ prognosis.tipster }
                                    prognosis={ prognosis.prognosis }
                                />
                            )
                    }
                </CardComponent>
            </div>
        );
    }
}
