import './Membership.scss';
import {useEffect, useState} from "react";
import Stepper from "./Stepper";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import {StateProvider} from "./membership-state-context";
import {Box, Container, makeStyles, Paper} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    boxWrapper: {
        marginBottom: "55px",
        minHeight: "calc(26vh + 260px)"
    },
    container: {
        position: "relative",
        zIndex: "1100",
        marginTop: "30px",
        marginBottom: "45px",
    }
}));

const MembershipPage = () => {
    const classes = useStyles();
    const [stripePromise, setStripePromise] = useState(null)

    useEffect(() => {
        const retrievePublishableKey = async () => {
            const publishableKey = process.env.REACT_APP_STRIPE_KEY;
            const stripe = loadStripe(publishableKey);
            setStripePromise(stripe)
        }
        retrievePublishableKey()
    }, [])

    return (
        <Box component="main" className={classes.boxWrapper}>
            <Container maxWidth="md" className={classes.container}>
                <StateProvider>
                    <Paper elevation={5}>
                        { stripePromise
                            ? <Elements options={{ locale: 'fr' }} stripe={stripePromise}>
                                <Stepper />
                            </Elements>
                            : null
                        }
                    </Paper>
                </StateProvider>
            </Container>
        </Box>
    )
}

export default MembershipPage;
