import './TipsterMyAccount.scss';
import React, { useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { UserStore } from "../../../stores/UserStore";
import { v4 as uuidv4 } from "uuid";
import ApiClient from "../../../services/api-client";
import PrognosisPreviewComponent from "../../../components/prognosis/prognosis-preview/PrognosisPreview";
import TipsterPreview from "../../../components/tipster/tipster-preview/TipsterPreview";
import CardComponent from "../../../components/card/Card";
import StatisticPreviewComponent from "../../../components/utils/statistic-preview/StatisticPreview";
import AuthenticationService from "../../../services/authentication/AuthenticationService";
import ScrollToTopComponent from "../../../components/utils/scroll-to-top/ScrollToTop";
import InfiniteScroll from "react-infinite-scroll-component";
import TelegramBannerComponent from "../../../components/telegram-banner/TelegramBanner";
import {fb} from "../../../services/firebase";

const PROGNOSES_AMOUNT = 20;

function TipsterMyAccount(props) {
    const api = new ApiClient();
    const authenticationService = new AuthenticationService();
    api.setBearerAuth(authenticationService.getToken());

    const user = UserStore.useState(store => store.user);

    const [tipster, setTipster] = useState(null);
    const [prognoses, setPrognoses] = useState([]);
    const [inProgressPrognoses, setInProgressPrognoses] = useState([]);
    const [validatedPrognoses, setValidatedPrognoses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMorePrognosesToFetch, setHasMorePrognosesToFetch] = useState(false);
    const fakePrognosesList = new Array(10).fill(undefined);
    const { id } = useParams();

    //const hasTipsterId = () => (id !== undefined && id !== null);

    useEffect(() => {
        api.tipster.getTipster(fb.auth().currentUser?.email, id, offset)
            .then(response => {
                const localValidatedPrognoses = splitPrognoses(response.prognoses);

                setTipster(response.tipster);
                setPrognoses(response.prognoses);
                setValidatedPrognoses(localValidatedPrognoses);
                setIsLoading(false);
                setOffset(response.prognoses.length);
                setHasMorePrognosesToFetch(response.prognoses.length >= PROGNOSES_AMOUNT);
            })
            .catch((error) => {
                props.history.push('/');
            });
    }, [id]);

    const fetchMoreData = () => {
        api.tipster.getTipster(user?.email, id, offset)
            .then(response => {
                const localValidatedPrognoses = splitPrognoses(response.prognoses);

                setPrognoses(response.prognoses);
                setValidatedPrognoses(validatedPrognoses.concat(localValidatedPrognoses));
                setOffset(offset + localValidatedPrognoses.length);
                setHasMorePrognosesToFetch(localValidatedPrognoses.length >= PROGNOSES_AMOUNT);
            }).catch(e => {
                setIsLoading(false)
            });
    }

    const splitPrognoses = (prognoses) => {
        if (prognoses.length > 0) {
            const notValidatedProg = prognoses.filter(prog => !prog.prognosis.hasBeenValidated);
            const validatedProg = prognoses.filter(prog => prog.prognosis.hasBeenValidated);

            if (inProgressPrognoses.length === 0) {
                setInProgressPrognoses(notValidatedProg);
            }

            return validatedProg;
        }
    }

    const getFetchingLoader = () => {
        return (
            <div id="tipster-prognoses-list">
                {
                    new Array(5).map(() =>
                        <Skeleton key={ uuidv4() } variant="rect" width="100%" height={166}/>
                    )
                }
            </div>
        )
    }

    return (
        <>
            <TelegramBannerComponent/>
            <div id="boxed-content">
                {
                    isLoading
                        ? <>
                            <Skeleton width="100%" height={ 350 } />

                            <div id="tipster-prognoses-list">
                                {
                                    fakePrognosesList.map(() =>
                                        <Skeleton key={ uuidv4() } variant="rect" width="100%" height={166}/>
                                    )
                                }
                            </div>
                        </>
                        : <>
                            <CardComponent type="account-view">
                                <div id="tipster-presentation">
                                    <TipsterPreview
                                        name={ tipster.nickname }
                                        picture={ tipster.picture }
                                        slug={ tipster.slug }
                                        disableHoverAnimation={ true }
                                        onlyBorderRadiusLeft={ true }/>

                                    <div id="presentation-container">
                                        <div id="statistics-container">
                                            <StatisticPreviewComponent bigText={ tipster.statistics.numberOfPrognoses }
                                                                       label={ "pronos"}/>

                                            <StatisticPreviewComponent bigText={ tipster.statistics.successRate + "%" }
                                                                       label={ "de réussite"}/>

                                            <StatisticPreviewComponent bigText={ tipster.statistics.averageRating }
                                                                       label={ "cote moy."}/>

                                            <StatisticPreviewComponent bigText={ tipster.statistics.roi + "%" }
                                                                       label={ "ROI"}/>

                                            <StatisticPreviewComponent bigText={ tipster.statistics.roc + "%" }
                                                                       label={ "ROC"}/>
                                        </div>

                                        { /*<a id="bankroll-link" href={ tipster.statistics.bankrollUrl } target="_blank" rel='noreferrer noopener'>
                                            Voir la bankroll <Icon fontSize={ 'small' }>open_in_new</Icon>
                                        </a> */
                                        }
                                    </div>
                                </div>
                            </CardComponent>

                            <h2 className="aligned-left">Pronostics en cours:</h2>

                            {
                                inProgressPrognoses?.length > 0
                                    ? <div id="tipster-prognoses-list-container">
                                        <div id="tipster-prognoses-list">
                                            { inProgressPrognoses.map(prognosis =>
                                                <PrognosisPreviewComponent
                                                    key={ prognosis.prognosis.id }
                                                    tipster={ prognosis.tipster }
                                                    prognosis={ prognosis.prognosis }
                                                    hasTipsterPreview={ false }
                                                />)
                                            }
                                        </div>
                                    </div>
                                    : <p>{ tipster.nickname } n'a pas de pronostics en cours.</p>
                            }

                            <h2 className="aligned-left" style={{ marginTop: 50 }}>Pronostics terminés:</h2>

                            {
                                validatedPrognoses?.length > 0
                                    ? <div id="tipster-prognoses-list-container">
                                        <InfiniteScroll
                                            dataLength={ validatedPrognoses.length }
                                            next={ () => fetchMoreData() }
                                            hasMore={ hasMorePrognosesToFetch }
                                            loader={ () => getFetchingLoader() }
                                            scrollThreshold={ 0.8 }
                                        >
                                                <div id="tipster-prognoses-list">
                                                    { validatedPrognoses.map(prognosis =>
                                                        <PrognosisPreviewComponent
                                                            key={ prognosis.prognosis.id }
                                                            tipster={ prognosis.tipster }
                                                            prognosis={ prognosis.prognosis }
                                                            hasTipsterPreview={ false }
                                                        />)
                                                    }
                                                </div>
                                        </InfiniteScroll>
                                    </div>
                                    : <p>{ tipster.nickname } n'a pas encore terminé de pronostics.</p>
                            }
                        </>
                }
            </div>

            <ScrollToTopComponent/>
        </>
    );
}

export default TipsterMyAccount;
