import PropTypes from 'prop-types';

export const tipsterType = PropTypes.exact({
    uid: PropTypes.string,
    nickname: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    twitterUrl: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.instanceOf(null).isRequired,
    ]),
    statistics: PropTypes.object,
    bookmakerReferrals: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    })).isRequired,
});
