import './PrognosisPreview.scss';
import TipsterPreviewComponent from "../../tipster/tipster-preview/TipsterPreview";
import { tipsterType } from "../../../types/tipster/TipsterType";
import { prognosisType } from "../../../types/prognosis/PrognosisType";
import { PrognosisFinalType } from "../../../models/prognosis-final-type/PrognosisFinalTypeModel";
import { Court } from "../../../models/court/CourtModel";
import ReactTimeAgo from "react-time-ago";
import clayCourtImage from '../../../assets/images/icons/clay_court.jpg'
import {Icon } from "@material-ui/core";
import {Link} from "react-router-dom";
import {Role} from "../../../models/role/RoleModel";
import {UserStore} from "../../../stores/UserStore";
import {
    isBrowser
} from "react-device-detect";
import {Bookmaker} from "../../../models/bookmaker/BookmakerModel";


const classNames = require('classnames');

const PrognosisPreviewComponent = ({ tipster, prognosis, hasTipsterPreview = true }) => {

    const user = UserStore.useState(store => store.user);

    const oddsButtonClassNames = classNames({
        'odds-button': true,
        'odds-button-green disabled': prognosis.finalType === PrognosisFinalType.WIN,
        'odds-button-red disabled': prognosis.finalType === PrognosisFinalType.LOSS,
        'odds-button-grey disabled': (prognosis.finalType !== PrognosisFinalType.WIN && prognosis.finalType !== PrognosisFinalType.LOSS),
    });

    const getCourtImage = (court) => {
        switch (court) {
            case Court.GROUND:
                return clayCourtImage;
            case Court.CLAY:
                return clayCourtImage;
            case Court.GRASS:
                return clayCourtImage;
            default:
                return null;
        }
    }

    const getTournament = ({tournament, tournamentType }) => {
        if (tournament && tournamentType) {
            return `${tournamentType} - ${tournament}`
        } else if (tournament) {
            return `${tournament}`
        } else if (tournamentType) {
            return `${tournamentType}`
        } else {
            return '';
        }
    }

    const formatStartDate = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    const getPrognosisFinalType = (type, odd) => {
        switch (type) {
            case PrognosisFinalType.LOSS:
                return `Prono perdu`;
            case PrognosisFinalType.REFUNDED:
                return `Prono remboursé`;
            case PrognosisFinalType.CANCELED:
                return `Prono annulé`;
            case PrognosisFinalType.WIN:
            default:
                return `Prono gagné`;
        }
    }

    const getPrognosisBookmakerUrl = (bookmaker) => {
        switch (bookmaker.name) {
            case Bookmaker.BETCLIC:
                return process.env.REACT_APP_BETCLIC_AFFILIATE_LINK;
            case Bookmaker.UNIBET:
                return process.env.REACT_APP_UNIBET_AFFILIATE_LINK;
            case Bookmaker.ZEBET:
                return process.env.REACT_APP_ZEBET_AFFILIATE_LINK;
            default:
                return bookmaker.url;
        }
    }

    return (
        <div className="prognosis-preview">
            {
                hasTipsterPreview
                    ? <div className="prognosis-preview__tipster-container">
                        <TipsterPreviewComponent
                            name={ tipster.nickname }
                            picture={ tipster.picture }
                            slug={ tipster.slug }
                            disableHoverAnimation={ true }
                            onlyBorderRadiusLeft={ isBrowser }
                        />
                      </div>
                    : <></>
            }
            <div className="prognosis-preview__prognosis-container">
                <div className="prognosis-preview__prognosis-container__header">
                    <div>
                        { /* <img className="court-image" src={ getCourtImage(prognosis.court) } alt="Terrain"/> */ }
                        <span>Publié <ReactTimeAgo date={ new Date(prognosis.publishDate) }/></span>
                    </div>

                    {
                        (user?.role === Role.ADMIN ||
                        (user?.role === Role.TIPSTER && tipster.slug === user?.nicknameSlug))
                        &&
                        <Link className="edit-prognosis" to={ `/${user?.role === Role.ADMIN ? 'admin' : 'tipster'}/pronostic/${prognosis.docId}` }>Modifier</Link>
                    }
                </div>

                <div className="prognosis-preview__prognosis-container__content">
                    { getTournament(prognosis) }
                    <div className="prognosis-preview__prognosis-container__versus-container">
                        <p className="player">
                            { prognosis.players[0] }
                            { prognosis.players[1]
                                ? <>
                                    <span className="player versus">-</span>
                                    { prognosis.players[1] }
                                </>
                                : <></>
                            }
                        </p>
                    </div>

                    <p className="starting-date">Débute le { formatStartDate(prognosis.startDate) }</p>

                    {
                        prognosis.hasAccess || user?.role === Role.ADMIN || user?.role === Role.TIPSTER
                            ? prognosis.analysis
                                ? <div className="prognosis">
                                    <p><u>Pronostic :</u> { prognosis.prognosis }</p>
                                    <p className="analysis"><u>Analyse :</u> { prognosis.analysis }</p>
                                </div>
                                : <p className="prognosis"><u>Pronostic :</u> { prognosis.prognosis }</p>
                            : user
                                ? user.role === Role.PREMIUM_MEMBER
                                    ? <div className="prognosis">
                                        <p>Vous n'êtes pas <u>abonné(e)</u> aux pronostics de ce tipster.</p>
                                    </div>
                                    : <div className="prognosis">
                                        <p>Vous n'êtes pas <u>abonné(e)</u> aux pronostics de ce tipster.</p>
                                        <Link className="prognosis-link" to="/abonnement">Cliquez ici pour vous abonner.</Link>
                                    </div>
                                : <div className="prognosis">
                                    <p>Vous devez être <u>connecté(e)</u> pour voir les pronostics.</p>
                                    <Link className="prognosis-link" to="/connexion">Cliquez ici pour vous connecter.</Link>
                                </div>
                    }

                    {
                        prognosis.hasAccess
                            ? <div className="odds-buttons-container">
                                {
                                    prognosis.hasBeenValidated
                                        ? <div className={ oddsButtonClassNames }>
                                            { getPrognosisFinalType(prognosis.finalType, prognosis.odds) }
                                        </div>
                                        : <></>
                                }

                                <a className="odds-button odds-button-yellow"
                                   href={ getPrognosisBookmakerUrl(prognosis.bookmaker) }
                                   target="_blank"
                                   rel='noreferrer noopener'>
                                    Cote à {prognosis.odds} sur {prognosis.bookmaker.name} -
                                    Mise BK {prognosis.bankrollBet}%
                                    <Icon fontSize={'small'}>open_in_new</Icon>
                                </a>
                            </div>
                            : <></>
                    }
                </div>
            </div>
        </div>
    );
}

PrognosisPreviewComponent.propTypes = {
    tipster: tipsterType,
    prognosis: prognosisType
}

export default PrognosisPreviewComponent;
