import React, { Component } from 'react';
import './Home.scss';
import LoadingHomePage from "./loading-home/LoadingHome";
import TipsterPreviewComponent from "../../components/tipster/tipster-preview/TipsterPreview";
import PrognosisPreviewComponent from "../../components/prognosis/prognosis-preview/PrognosisPreview";
import ApiClient from "../../services/api-client";
import AuthenticationService from "../../services/authentication/AuthenticationService";
import { UserStore } from "../../stores/UserStore";
import ScrollToTopComponent from "../../components/utils/scroll-to-top/ScrollToTop";
import InfiniteScroll from "react-infinite-scroll-component";
import {Skeleton} from "@material-ui/lab";
import {v4 as uuidv4} from "uuid";
import TelegramBannerComponent from "../../components/telegram-banner/TelegramBanner";
import { getTipstersList } from "../../helpers/utils";
import GlobalStats from "../../components/home/global-stats/GlobalStats";

const DEFAULT_STATE = {
    user: null,
    tipstersList: getTipstersList(),
    stats: {},
    prognosesList: [],
    offset: 0,
    hasMorePrognosesToFetch: true,
    isLoading: true
}

const PROGNOSES_AMOUNT = 50;

export default class HomePage extends Component {

    api = new ApiClient();
    authenticationService = new AuthenticationService();
    state = { ...DEFAULT_STATE };

    componentDidMount() {
        if (this.authenticationService.getToken()) {
            this.setState({ user: UserStore.getRawState().user });

            this.api.setBearerAuth(this.authenticationService.getToken());

            setTimeout(() => {
                const user = UserStore.getRawState().user;
                this.api.data.getCommonData(user ? user.email : null, this.state.offset)
                    .then(data => {
                        this.setState({
                            stats: data.stats,
                            prognosesList: data.prognoses,
                            offset: data.prognoses.length,
                            hasMorePrognosesToFetch: data.prognoses.length >= PROGNOSES_AMOUNT,
                            isLoading: false
                        });
                    }).catch(e => {
                        console.log('error', e);
                        this.setState({
                            isLoading: false,
                        });
                    });
            }, 100);
        } else {
            this.api.data.getCommonData(null, this.state.offset)
                .then(data => {
                    this.setState({
                        stats: data.stats,
                        prognosesList: data.prognoses,
                        offset: data.prognoses.length,
                        hasMorePrognosesToFetch: data.prognoses.length >= PROGNOSES_AMOUNT,
                        isLoading: false
                    });
                }).catch(e => {
                    console.log('error', e);
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    fetchMoreData = () => {
        const { user, offset } = this.state;

        this.api.data.getCommonData(user ? user.email : null, offset)
            .then(data => {
                setTimeout(() => {
                    this.setState({
                        stats: data.stats,
                        prognosesList: this.state.prognosesList.concat(data.prognoses),
                        offset: (offset + data.prognoses.length),
                        hasMorePrognosesToFetch: data.prognoses.length >= PROGNOSES_AMOUNT,
                    });
                }, 300);
            }).catch(e => {
                console.log('error', e);
                this.setState({
                    isLoading: false,
                });
            });
    }

    getFetchingLoader = () => {
        return <div id="prognoses-preview-list">
            {
                new Array(5).map(() =>
                    <Skeleton key={ uuidv4() } variant="rect" width="100%" height={166}/>
                )
            }
        </div>
    }

    render() {
        return (
            this.state.isLoading
                ? <LoadingHomePage/>
                : <>
                    <TelegramBannerComponent/>

                    <div id="website-tagline">
                        <h2>
                            L'expertise de la Team SBN au service des parieurs<br/>
                            PASSION - EXPERIENCE - RIGUEUR
                        </h2>
                    </div>

                    <div id="boxed-content" style={{ alignItems: 'stretch' }}>

                        <h2 style={{ margin: '0 auto 20px', textAlign: 'center' }}>Notre bilan global:</h2>
                        <GlobalStats numberOfPrognoses={ this.state.stats.numberOfPrognoses }
                                     gains={ null }
                                     roi={ this.state.stats.roi }
                                     roc={ this.state.stats.roc } />

                        <h2 style={{ margin: '50px auto 0', textAlign: 'center' }}>Filtrer par tipster:</h2>
                        { /*<h2 style={{ margin: '0 auto', textAlign: 'center' }}>Filtrer par tipster:</h2> */ }
                        <span style={{ margin: '0 auto 20px', textAlign: 'center' }}>(permet aussi de voir le bilan)</span>

                        <div id={"tipsters-preview-list"}>
                            {
                                this.state.tipstersList.map(tipster =>
                                    <TipsterPreviewComponent
                                        key={ tipster.slug }
                                        name={ tipster.name }
                                        picture={ tipster.picture }
                                        slug={ tipster.slug }
                                    />
                                )
                            }
                        </div>

                        <h2 style={{ margin: '50px auto 20px', textAlign: "center" }}>Tous nos pronostics en cours:</h2>

                        <InfiniteScroll
                            dataLength={ this.state.prognosesList.length }
                            next={ this.fetchMoreData }
                            hasMore={ this.state.hasMorePrognosesToFetch }
                            loader={ this.getFetchingLoader }
                            scrollThreshold={ 0.8 }
                        >
                            <div id="prognoses-preview-list">
                                {
                                    this.state.prognosesList.length > 0
                                        ? this.state.prognosesList.map(currentPrognosis =>
                                            <PrognosisPreviewComponent
                                                key={ currentPrognosis.prognosis.id }
                                                user={ this.state.user }
                                                tipster={ currentPrognosis.tipster }
                                                prognosis={ currentPrognosis.prognosis }
                                            />
                                        )
                                        : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <p style={{ textAlign: 'center' }}>Il n'y a aucun pronostic en cours.</p>
                                            <a href="/" className="app-link" style={{ textAlign: 'center' }}>Rafraîchir la page</a>
                                        </div>
                                }
                            </div>
                        </InfiniteScroll>
                    </div>

                    <ScrollToTopComponent/>
                </>
        );
    }
};
