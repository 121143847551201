import React, { Component } from "react";
import { Court } from "../../../../models/court/CourtModel";
import './TipsterUpdatePrognosis.scss';
import CardComponent from "../../../../components/card/Card";
import InputComponent from "../../../../components/utils/input/Input";
import ApiClient from "../../../../services/api-client";
import AuthenticationService from "../../../../services/authentication/AuthenticationService";
import SelectComponent from "../../../../components/utils/select/Select";
import DateTimeComponent from "../../../../components/utils/date-time/DateTime";
import ButtonComponent from "../../../../components/utils/button/Button";
import Alert from "@material-ui/lab/Alert";
import {PrognosisFinalType} from "../../../../models/prognosis-final-type/PrognosisFinalTypeModel";
import {TournamentType} from "../../../../models/tournament-type/TournamentTypeModel";

const DEFAULT_STATE = {
    id: null,
    prognosisId: null,
    isLoadingPrognosis: true,
    isUpdatingPrognosis: false,
    tipster: null,
    prognosis_tournament: '',
    prognosis_tournamentType: '',
    prognosis_player_one: '',
    prognosis_player_two: '',
    prognosis_court: Court.GROUND,
    prognosis_start_date: '',
    prognosis_prognosis: '',
    prognosis_analysis: '',
    prognosis_bookmaker: '',
    prognosis_isPublic: 'non',
    prognosis_category: 'other',
    prognosis_finalType: '',
    prognosis_odds: '',
    prognosis_bankroll_bet: '',
    isTournamentInvalid: false,
    isTournamentTypeInvalid: false,
    isPlayerOneInvalid: false,
    isPlayerTwoInvalid: false,
    isCourtInvalid: false,
    isPrognosisInvalid: false,
    isAnalysisInvalid: false,
    isBookmakerInvalid: false,
    isPublicInvalid: false,
    isCategoryInvalid: false,
    isOddsInvalid: false,
    isBankrollBetInvalid: false,
    isLoading: false,
    errorMessage: null
};

const TOURNAMENT_TYPES_VALUES = [
    { label: 'Football', value: TournamentType.FOOTBALL },
    { label: 'Rugby', value: TournamentType.RUGBY },
    { label: 'Tennis', value: TournamentType.TENNIS },
    { label: 'NBA', value: TournamentType.NBA },
    { label: 'NFL', value: TournamentType.NFL },
];

const BOOKMAKER_VALUES = [];

const FINAL_TYPE_OPTIONS = [
    { label: '---', value: '' },
    { label: `Pronostic gagné`, value: PrognosisFinalType.WIN },
    { label: `Pronostic perdu`, value: PrognosisFinalType.LOSS },
    { label: `Pronostic remboursé`, value: PrognosisFinalType.REFUNDED },
    { label: `Pronostic annulé`, value: PrognosisFinalType.CANCELED },
];

const IS_PUBLIC_VALUES = [
    { label: 'Oui', value: 'oui' },
    { label: 'Non', value: 'non' },
];

export default class AdminUpdatePrognosisPage extends Component {

    api;
    authenticationService = new AuthenticationService();

    state = { ...DEFAULT_STATE };

    componentDidMount() {
        const prognosisId = this.props.match.params.id;

        this.setState({
            prognosisId
        });

        this.api = new ApiClient();
        this.api.setBearerAuth(this.authenticationService.getToken());

        this.api.tipster.getPrognosisById(prognosisId)
            .then(response => {
                if (BOOKMAKER_VALUES.length === 0) {
                    response.tipster.bookmakerReferrals.forEach(bookmaker => {
                        BOOKMAKER_VALUES.push({
                            label: bookmaker.name,
                            value: bookmaker.url
                        });
                        console.log(BOOKMAKER_VALUES);
                    });
                }

                this.setState({
                    isLoadingPrognosis: false,
                    tipster: {
                        slug: response.prognosis.tipsterSlug
                    },
                    id: response.prognosis.id,
                    prognosis_tournament: response.prognosis.tournament,
                    prognosis_tournamentType: response.prognosis.tournamentType,
                    prognosis_player_one: response.prognosis.players[0],
                    prognosis_player_two: response.prognosis.players[1],
                    prognosis_court: response.prognosis.court,
                    prognosis_start_date: response.prognosis.startDate,
                    prognosis_prognosis: response.prognosis.prognosis,
                    prognosis_analysis: response.prognosis.analysis ? response.prognosis.analysis : '',
                    prognosis_bookmaker: response.prognosis.bookmaker.url,
                    prognosis_isPublic: response.prognosis.isPublic ? 'oui' : 'non',
                    prognosis_category: response.prognosis.category,
                    prognosis_finalType: response.prognosis.hasBeenValidated ? response.prognosis.finalType : '',
                    prognosis_odds: response.prognosis.odds,
                    prognosis_bankroll_bet: response.prognosis.bankrollBet,
                });
            }).catch(error => {
                console.log(error);
            })
    }

    onSubmitForm = async () => {
        if (this.isValidForm() && !this.state.isLoading) {
            this.setState({ isUpdatingPrognosis: true, errorMessage: null });

            const tipster = {
                slug: this.state.tipster.slug
            }

            const bookmaker = BOOKMAKER_VALUES.find(bookmaker => bookmaker.value === this.state.prognosis_bookmaker);

            const prognosis = {
                id: this.state.id,
                tournament: this.state.prognosis_tournament,
                tournamentType: this.state.prognosis_tournamentType,
                players: [ this.state.prognosis_player_one, this.state.prognosis_player_two ],
                prognosis: this.state.prognosis_prognosis,
                analysis: this.state.prognosis_analysis,
                odds: Number(this.state.prognosis_odds),
                bankrollBet: Number(this.state.prognosis_bankroll_bet),
                court: this.state.prognosis_court,
                bookmaker: {
                    name: bookmaker.label,
                    url: bookmaker.value
                },
                isPublic: this.state.prognosis_isPublic === 'oui',
                category: this.state.prognosis_category,
                hasBeenValidated: this.state.prognosis_finalType !== '',
                finalType: this.state.prognosis_finalType,
                startDate: new Date(this.state.prognosis_start_date).toISOString(),
                tipsterSlug: this.state.tipster.slug,
            }

            try {
                await this.api.tipster.updatePrognosis(this.state.prognosisId, tipster, prognosis);
                this.props.history.goBack();
            } catch (e) {
                this.setState({
                    isLoading: false,
                    errorMessage: e.message
                });
            }
        }
    }

    isValidForm() {
        const { prognosis_tournament, prognosis_tournamentType, prognosis_player_one, prognosis_player_two, prognosis_court, prognosis_start_date, prognosis_prognosis, prognosis_analysis, prognosis_bookmaker, prognosis_odds, prognosis_bankroll_bet } = this.state;
        let isValid = true;

        if (prognosis_tournament === '') {
            this.setTournamentInvalid(true);
            isValid = false;
        } else {
            this.setTournamentInvalid(false);
        }

        if (prognosis_tournamentType === '') {
            this.setTournamentTypeInvalid(true);
            isValid = false;
        } else {
            this.setTournamentTypeInvalid(false);
        }

        if (prognosis_player_one === '') {
            this.setPlayerOneInvalid(true);
            isValid = false;
        } else {
            this.setPlayerOneInvalid(false);
        }

        if (prognosis_player_two === '') {
            this.setPlayerTwoInvalid(true);
            isValid = false;
        } else {
            this.setPlayerTwoInvalid(false);
        }

        if (prognosis_court === '') {
            this.setCourtInvalid(true);
            isValid = false;
        } else {
            this.setCourtInvalid(false);
        }

        if (!prognosis_start_date || prognosis_start_date.toString() === '' || prognosis_start_date.toString() === 'Invalid Date') {
            isValid = false;
        }

        if (prognosis_prognosis === '') {
            this.setPrognosisInvalid(true);
            isValid = false;
        } else {
            this.setPrognosisInvalid(false);
        }

        if (prognosis_analysis.length > process.env.REACT_APP_PROGNOSIS_ANALYSIS_CHARACTERS_LIMIT) {
            this.setAnalysisInvalid(true);
            isValid = false;
        } else {
            this.setAnalysisInvalid(false);
        }

        if (prognosis_bookmaker === '') {
            this.setBookmakerInvalid(true);
            isValid = false;
        } else {
            this.setBookmakerInvalid(false);
        }

        if (prognosis_odds === '' || isNaN(prognosis_odds)) {
            this.setOddsInvalid(true);
            isValid = false;
        } else {
            this.setOddsInvalid(false);
        }

        if (prognosis_bankroll_bet === '' || isNaN(prognosis_bankroll_bet)) {
            this.setBankrollBetInvalid(true);
            isValid = false;
        } else {
            this.setBankrollBetInvalid(false);
        }

        return isValid;
    }

    onSetTournament = (event) => { this.setState({ prognosis_tournament: event.target.value }); }
    onSetTournamentType = (event) => { this.setState({ prognosis_tournamentType: event.target.value }); }
    onSetPlayerOne = (event) => { this.setState({ prognosis_player_one: event.target.value }); }
    onSetPlayerTwo = (event) => { this.setState({ prognosis_player_two: event.target.value }); }
    onSetCourt = (event) => { this.setState({ prognosis_court: event.target.value }); }
    onSetStartDate = (event) => { this.setState({ prognosis_start_date: event }); }
    onSetPrognosis = (event) => { this.setState({ prognosis_prognosis: event.target.value }); }
    onSetAnalysis = (event) => { this.setState({ prognosis_analysis: event.target.value }); }
    onSetBookmaker = (event) => { this.setState({ prognosis_bookmaker: event.target.value }); }
    onSetIsPublic = (event) => { this.setState({ prognosis_isPublic: event.target.value }); }
    onSetCategory = (event) => { this.setState({ prognosis_category: event.target.value }); }
    onSetFinalType = (event) => { this.setState({ prognosis_finalType: event.target.value }); }
    onSetOdds = (event) => { this.setState({ prognosis_odds: event.target.value }); }
    onSetBankrollBet = (event) => { this.setState({ prognosis_bankroll_bet: event.target.value }); }

    setTournamentInvalid = (isInvalid) => this.setState({ isTournamentInvalid: isInvalid });
    setTournamentTypeInvalid = (isInvalid) => this.setState({ isTournamentTypeInvalid: isInvalid });
    setPlayerOneInvalid = (isInvalid) => this.setState({ isPlayerOneInvalid: isInvalid });
    setPlayerTwoInvalid = (isInvalid) => this.setState({ isPlayerTwoInvalid: isInvalid });
    setCourtInvalid = (isInvalid) => this.setState({ isCourtInvalid: isInvalid });
    setPrognosisInvalid = (isInvalid) => this.setState({ isPrognosisInvalid: isInvalid });
    setAnalysisInvalid = (isInvalid) => this.setState({ isAnalysisInvalid: isInvalid });
    setBookmakerInvalid = (isInvalid) => this.setState({ isBookmakerInvalid: isInvalid });
    setIsPublicInvalid = (isInvalid) => this.setState({ isPublicInvalid: isInvalid });
    setCategoryInvalid = (isInvalid) => this.setState({ isCategoryInvalid: isInvalid });
    setOddsInvalid = (isInvalid) => this.setState({ isOddsInvalid: isInvalid });
    setBankrollBetInvalid = (isInvalid) => this.setState({ isBankrollBetInvalid: isInvalid });

    render() {
        return (
            <div id="boxed-content">
                <CardComponent type="dashboard">
                    <h1 id="dashboard-title">Modifier un pronostic</h1>

                    {
                        this.state.errorMessage
                            ? <Alert severity="error" style={{ marginBottom: '20px' }}>{ this.state.errorMessage }</Alert>
                            : <></>
                    }

                    {
                        this.state.isLoadingPrognosis
                            ? <p>Chargement...</p>
                            : <>
                                <div id="tournament-info" className="half-field mb-10px">
                                    <SelectComponent id="tipster-publish__tournamentType"
                                                     label="Sport *"
                                                     value={ this.state.prognosis_tournamentType }
                                                     options={ TOURNAMENT_TYPES_VALUES }
                                                     isInvalid={ this.state.isTournamentTypeInvalid }
                                                     onComplete={ this.onSetTournamentType }/>

                                    <InputComponent
                                        id="tipster-publish__tournament"
                                        label="Nom du tournoi *"
                                        defaultValue={ this.state.prognosis_tournament }
                                        isInvalid={ this.state.isTournamentInvalid }
                                        onComplete={ this.onSetTournament }
                                    />
                                </div>

                                <div id="players-info" className="half-field mb-10px">
                                    <InputComponent
                                        id="tipster-publish__player-one"
                                        label="Joueur 1 *"
                                        defaultValue={ this.state.prognosis_player_one }
                                        isInvalid={ this.state.isPlayerOneInvalid }
                                        onComplete={ this.onSetPlayerOne }
                                    />

                                    <InputComponent
                                        id="tipster-publish__player-two"
                                        label="Joueur 2 *"
                                        defaultValue={ this.state.prognosis_player_two }
                                        isInvalid={ this.state.isPlayerTwoInvalid }
                                        onComplete={ this.onSetPlayerTwo }
                                    />
                                </div>

                                <div id="match-info" className="mb-30px">
                                    <DateTimeComponent
                                        label="Date de début *"
                                        value={ new Date(this.state.prognosis_start_date) }
                                        disablePast={ false }
                                        format={ "dd/MM/yyyy" }
                                        onComplete={ this.onSetStartDate }/>
                                </div>

                                <InputComponent
                                    id="tipster-publish__prognosis"
                                    label="Mon pronostic *"
                                    defaultValue={ this.state.prognosis_prognosis }
                                    isInvalid={ this.state.isPrognosisInvalid }
                                    onComplete={ this.onSetPrognosis }
                                    hasMarginBottom={ true }
                                />

                                <InputComponent
                                    id="tipster-publish__analysis"
                                    label="Mon analyse (facultatif)"
                                    defaultValue={ this.state.prognosis_analysis }
                                    isInvalid={ this.state.isAnalysisInvalid }
                                    invalidText={ "L'analyse ne doit pas dépasser 400 caractères." }
                                    onComplete={ this.onSetAnalysis }
                                    hasMarginBottom={ true }
                                />

                                <div id="betting-info" className="half-field mb-10px">
                                    <SelectComponent id="tipster-publish__bookmaker"
                                                     label="Bookmaker *"
                                                     value={ this.state.prognosis_bookmaker }
                                                     options={ BOOKMAKER_VALUES }
                                                     isInvalid={ this.state.isBookmakerInvalid }
                                                     onComplete={ this.onSetBookmaker }/>

                                    { /*<div className="half-field">
                                        <InputComponent
                                            id="tipster-publish__odds"
                                            label="Cote *"
                                            type="number"
                                            defaultValue={ this.state.prognosis_odds }
                                            isInvalid={ this.state.isOddsInvalid }
                                            invalidText={ "La cote doit être un nombre." }
                                            onComplete={ this.onSetOdds }
                                        />

                                        <InputComponent
                                            id="tipster-publish__bk-bet"
                                            label="% Mise BK *"
                                            type="number"
                                            defaultValue={ this.state.prognosis_bankroll_bet }
                                            isInvalid={ this.state.isBankrollBetInvalid }
                                            invalidText={ "La mise doit être un nombre." }
                                            onComplete={ this.onSetBankrollBet }
                                        />
                                    </div> */ }
                                    <InputComponent
                                        id="tipster-publish__odds"
                                        label="Cote *"
                                        type="number"
                                        defaultValue={ this.state.prognosis_odds }
                                        isInvalid={ this.state.isOddsInvalid }
                                        invalidText={ "La cote doit être un nombre." }
                                        onComplete={ this.onSetOdds }
                                    />

                                    <InputComponent
                                        id="tipster-publish__bk-bet"
                                        label="% Mise BK *"
                                        type="number"
                                        defaultValue={ this.state.prognosis_bankroll_bet }
                                        isInvalid={ this.state.isBankrollBetInvalid }
                                        invalidText={ "La mise doit être un nombre." }
                                        onComplete={ this.onSetBankrollBet }
                                    />
                                </div>

                                <div className="third-field">
                                    <SelectComponent id="tipster-publish__isPublic"
                                                     label="Pronostic public *"
                                                     value={ this.state.prognosis_isPublic }
                                                     options={ IS_PUBLIC_VALUES }
                                                     isInvalid={ this.state.isPublicInvalid }
                                                     onComplete={ this.onSetIsPublic }/>

                                    <SelectComponent id="tipster-publish__prognosis-validate"
                                                     label="Valider le prono"
                                                     value={ this.state.prognosis_finalType }
                                                     options={ FINAL_TYPE_OPTIONS }
                                                     onComplete={ this.onSetFinalType }/>
                                </div>

                                <ButtonComponent
                                    id="tipster-publish-prognosis-submit-button"
                                    label="Modifier le prono"
                                    onClick={ this.onSubmitForm }
                                    isLoading={ this.state.isUpdatingPrognosis }
                                    style={{ marginTop: 30}} />
                            </>
                    }
                </CardComponent>
            </div>
        );
    }
}
