export const materialStyles = {
    theme: {
        palette: {
            primary: {
                main: `#4285f4`,
            },
            secondary: {
                main: '#11cb5f',
            },
            white: {
                main: '#FFF'
            }
        },
    },
    headerSettingsButton: {
        height: '32px',
        width: '32px',
        fontSize: '32px',
        color: '#8f9bb3',
        '&:hover': {
            color: '#4285f4'
        }
    },
    textField: {
        fontSize: '18px',
        color: '#222b45'
    },
    button: {
        width: '100%',
        background: 'linear-gradient(40deg, #45cafc, #303f9f) !important',
        fontSize: '16px',
        padding: '14px 30px',
        borderRadius: '30px',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
        '&:hover': {
            boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)',
        }
    }
};
