import React, { Component } from "react";
import './TipsterDashboard.scss';
import CardComponent from "../../../components/card/Card";
import DashboardCellComponent from "../../../components/dashboard/cell/DashboardCell";
import { UserStore } from "../../../stores/UserStore";

export default class TipsterDashboardPage extends Component {

    state = {
        user: null
    }

    componentDidMount() {
        const userStoreState = UserStore.getRawState();
        this.setUser(userStoreState.user);

        UserStore.subscribe(store => store.user, fetchedUser => {
            this.setUser(fetchedUser);
        });
    }

    setUser = (user) => {
        this.setState({
            user
        });
    }

    render() {
        return (
            <div id="boxed-content" className="aligned-left">
                <CardComponent type="dashboard">
                    <h1 id="dashboard-title">Bonjour { this.state.user?.nickname },</h1>

                    <DashboardCellComponent
                        label="Publier un pronostic"
                        backgroundImage={ null }
                        to="/tipster/nouveau-prono"
                    />

                    <DashboardCellComponent
                        label="Pronostics en attente de validation"
                        to="/tipster/en-attente"
                    />

                    <DashboardCellComponent
                        label="Mes derniers pronostics"
                        to="/tipster/mes-pronos"
                    />
                </CardComponent>
            </div>
        )
    }
}
