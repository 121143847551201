import React, { useEffect, useState } from 'react';
import './SetNewPassword.scss';
import {fb} from "../../../../services/firebase";
import CardComponent from "../../../../components/card/Card";
import InputComponent from "../../../../components/utils/input/Input";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../../../components/utils/button/Button";
import { CheckCircleOutlineRounded } from "@material-ui/icons";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function SetNewPasswordPage(props) {
    let query = useQuery();

    const [ passwordResetCode, setPasswordResetCode ] = useState('');

    const [ newPassword, setNewPassword ] = useState('');
    const [ isInvalidNewPassword, setInvalidNewPassword ] = useState(false);
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
    const [ isInvalidNewPasswordConfirm, setInvalidNewPasswordConfirm ] = useState(false);
    const [ notMatchingPasswords, setNotMatchingPasswords ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    useEffect(() => {
        const verificationCode = query.get('oobCode');

        if (verificationCode === null || verificationCode === '') {
            return props.history.push('/');
        }

        // verify code validity
        fb.auth().verifyPasswordResetCode(verificationCode)
            .then(function(email) {
                setPasswordResetCode(verificationCode);
            })
            .catch(function() {
                return props.history.push('/');
            });
    }, []);

    const onSubmitForm = async () => {
        if (loading) { return; }

        if (newPassword !== '' && newPasswordConfirm !== '' && (newPassword === newPasswordConfirm)) {
            setLoading(true);

            fb.auth().confirmPasswordReset(passwordResetCode, newPassword)
                .then(function() {
                    setSuccess(true);
                    setLoading(false);
                })
                .catch(function() {
                    setLoading(false);
                });
        } else {
            if (newPassword === '') {
                setInvalidNewPassword(true);
            }

            if (newPasswordConfirm === '') {
                setInvalidNewPasswordConfirm(true);
            }

            if (newPassword !== newPasswordConfirm) {
                setInvalidNewPassword(true);
                setInvalidNewPasswordConfirm(true);
                setNotMatchingPasswords(true);
            }
        }
    }

    const goToLoginPage = () => {
        props.history.push('/connexion');
    }

    return (
        <CardComponent type="authentication">
            <h1>Mot de passe oublié</h1>

            {
                success
                    ? <SetNewPasswordSuccessComponent didClick={ goToLoginPage }/>
                    : <>
                        <InputComponent
                            id="forgotten-password__new-password-input"
                            label="Nouveau mot de passe *"
                            isInvalid={ isInvalidNewPassword }
                            type="password"
                            invalidText={ notMatchingPasswords ? 'Les deux mot de passe doivent correspondre.' : 'Ce champs est requis.'}
                            onComplete={ (event) => setNewPassword(event.target.value) }
                        />

                        <InputComponent
                            id="forgotten-password__new-password-confirm-input"
                            label="Confirmation du mot de passe *"
                            isInvalid={ isInvalidNewPasswordConfirm }
                            type="password"
                            invalidText={ notMatchingPasswords ? 'Les deux mot de passe doivent correspondre.' : 'Ce champs est requis.'}
                            onComplete={ (event) => setNewPasswordConfirm(event.target.value) }
                        />

                        <ButtonComponent
                            id="authentication-submit-button"
                            label="Réinitialiser"
                            onClick={ onSubmitForm }
                            isLoading={ loading }/>
                    </>

            }
        </CardComponent>
    );
}

const SetNewPasswordSuccessComponent = ({ didClick }) => {
    return (
        <>
            <CheckCircleOutlineRounded style={{ fontSize: 96, color: '#4caf50', margin: '0 auto 40px' }}/>
            <p>Votre mot de passe a bien été modifié.</p>
            <p style={{ marginTop: 15 }}>Vous pouvez dès à présenter vous connecter avec votre adresse email ainsi que votre nouveau mot de passe.</p>
            <ButtonComponent
                id="authentication-submit-button"
                label="Se connecter"
                onClick={ didClick }
                isLoading={ false }/>
        </>
    )
}
