import React from 'react';
import './GlobalStats.scss';

const classNames = require('classnames');

const GlobalStats = ({ numberOfPrognoses, gains, roi, roc, backgroundColor = "white" }) => {

    const gainsClassNames = classNames({
        'global-stat-value': true,
        'positive': gains >= 0,
        'negative': gains < 0,
    });

    const roiClassNames = classNames({
        'global-stat-value': true,
        'positive': roi >= 0,
        'negative': roi < 0,
    });

    const rocClassNames = classNames({
        'global-stat-value': true,
        'positive': roc >= 0,
        'negative': roc < 0,
    });

    return (
        <div id="global-stats-wrapper">
            <div className="global-stat-card" style={{ backgroundColor }}>
                <h2 className="global-stat-value">{ numberOfPrognoses }</h2>
                <p className="global-stat-text-info">Pronos</p>
            </div>

            { gains && <div className="global-stat-card" style={{ backgroundColor }}>
                <h2 className={ gainsClassNames }>{ gains }€</h2>
                <p className="global-stat-text-info">Bénéfice</p>
            </div> }

            <div className="global-stat-card" style={{ backgroundColor }}>
                <h2 className={ roiClassNames }>{ roi }%</h2>
                <p className="global-stat-text-info">ROI</p>
            </div>

            <div className={ ["global-stat-card", gains === null ? "without-gains" : ""].join(" ") } style={{ backgroundColor }}>
                <h2 className={ rocClassNames }>{ roc }%</h2>
                <p className="global-stat-text-info">ROC</p>
            </div>

            <div id="global-stat-blank-container" style={{ height: 1 }}/>
        </div>
    );
}

export default GlobalStats;
