import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Box,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import {
    SentimentVeryDissatisfied, Warning
} from '@material-ui/icons';
import StepperIcons from "./StepperIcons";
import MembershipPaymentForm from "./Forms/MembershipPaymentForm";
import {
    useStripe,
    useElements,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import { useStateValue } from "./membership-state-context";
import StepConnector from './StepConnector'
import MembershipOffersForm from "./Forms/MembershipOffersForm";
import MembershipTipstersListForm from "./Forms/MembershipTipstersListForm";
import ModalComponent from "../../components/utils/modal/Modal";
import ApiClient from "../../services/api-client";
import AuthenticationService from "../../services/authentication/AuthenticationService";
import { UserStore } from "../../stores/UserStore";
import { withRouter } from "react-router-dom";
import logo from '../../assets/images/logo/logo.png';
import {getTipstersList, getTipstersNames} from "../../helpers/utils";

// OVERALL STYLE
const style = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {
        position: "relative",
        marginTop: "-8px",
        padding: "10px 20px",
        borderTop: "1px solid #eaeaf0",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: theme.palette.background.paper
    },
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    buttonWrapper: {
        justifyContent: "flex-end"
    },
}));

const StepContent = ({ step, onSelectOffer }) => {
    switch (step) {
        case 0:
            return <MembershipOffersForm onSelect={ onSelectOffer } />;
        case 1:
            return <MembershipTipstersListForm />;
        case 2:
            return <MembershipPaymentForm />;
        default:
            return <></>;
    }
}

const Steppers = (props) => {

    const api = new ApiClient();
    const authenticationService = new AuthenticationService();

    const classes = style();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const cardStatus = true;

    const stripe = useStripe();
    const elements = useElements();
    const [{ formValues }, dispatch] = useStateValue();

    const [ isModalOpen, setModalOpen ] = useState(false);
    const [ leftTipstersToSelect, setLeftTipstersToSelect ] = useState(0);

    const [ paymentErrorMessage, setPaymentErrorMessage] = useState(null);

    useEffect(() => {
        dispatch({ type: 'emptyFormValue' });
    }, [dispatch]);

    const handleNext = () => {
        if (activeStep === 0) {
            const offer = formValues.selected_offer;
            if (offer === "three") {
                dispatch({
                    type: 'editFormValue',
                    key: 'tipsters_list',
                    value: getTipstersNames()
                });
                setActiveStep(2);
            } else {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
            const offer = formValues.selected_offer;
            const tipstersList = formValues.tipsters_list;
            let maxTipsters;

            switch (offer) {
                case "one":
                    maxTipsters = 1;
                    break;
                case "two":
                    maxTipsters = 2;
                    break;
                case "three":
                    maxTipsters = getTipstersList().length;
                    break;
                default:
                    maxTipsters = 1;
            }

            if (tipstersList.length === maxTipsters) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            } else {
                setLeftTipstersToSelect(maxTipsters - tipstersList.length);
                setModalOpen(true);
            }
        } else if (activeStep === 2) {
            onPayClicked().then(() => null);
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        if (loading) { return; }

        setPaymentErrorMessage(null);

        if (activeStep === 2) {
            const offer = formValues.selected_offer;

            if (offer === "three") {
                setActiveStep(0);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    }
    const handleChosenOffer = () => {
        handleNext();
    }

    const onPayClicked = async () => {
        setLoading(true);
        setPaymentErrorMessage(null);

        api.setBearerAuth(authenticationService.getToken());

        const cardElement = elements.getElement(CardCvcElement);

        try {
            const { email, firstName, lastName, stripeCustomerId } = UserStore.getRawState().user;
            const { token } = await createToken(cardElement);
            const { selected_offer_id: plan, coupon } = formValues;

            const paymentIntent = await api.membership.generatePaymentIntent(stripeCustomerId, token.id, plan, email, coupon);

            const confirmedPayment = await stripe.confirmCardPayment(paymentIntent.client_secret, stripeDataObjectConverter(firstName, lastName, email, cardElement));

            if (!confirmedPayment.hasOwnProperty('error')) {
                await api.membership.createSubscription(stripeCustomerId, paymentIntent.id, paymentIntent.price / 100, plan, email, formValues.tipsters_list, coupon);

                const fetchedUser = await api.user.getUser(email);
                UserStore.update(store => { store.user = fetchedUser });

                setActiveStep(prevActiveStep => prevActiveStep + 1);
            } else {
                setPaymentErrorMessage(confirmedPayment.error.message);
            }
        } catch (error) {
            console.log(error);
            setPaymentErrorMessage(error.error.message);
        } finally {
            setLoading(false);
        }
    }

    const createToken = async (cardElement) => {
        try {
            return await stripe.createToken(cardElement);
        } catch (error) {
            throw error;
        }
    }

    const goToHome = () => {
        props.history.push('/');
    }

    return (
        <>
            <Stepper alternativeLabel className={classes.stepper} connector={<StepConnector />} activeStep={activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2, 3].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className={classes.mainBox}>
                {activeStep === 3 ?
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        justify="space-around"
                        alignItems="center"
                    >
                        { cardStatus
                            ?
                            <img style={{ width: 450, maxWidth: '90%', margin: '30px 0' }} id="logo" src={ logo } alt="SportsBetNews Logo"/>
                            :
                            <SentimentVeryDissatisfied fontSize="large" color="error" />
                        }
                        <div style={{ textAlign: 'center' }}>
                            <h3 style={{ margin: '0 0 15px' }}>Vous faites maintenant parti(e) des membres de SportsBetNews !</h3>
                            <p> Profitez dès à présent des pronostics de vos tipsters préférés.</p>
                        </div>
                        {
                            cardStatus
                                ? <Button variant="contained" color="primary" onClick={ goToHome } className={classes.button} style={{ margin: '30px 0' }}>
                                    Accéder aux pronostics
                                </Button>
                                : <Button onClick={handleBack} className={classes.button}>
                                    Recommencer
                                </Button>
                        }
                    </Grid>
                    :
                    <form autoComplete="off" className={classes.form} onSubmit={e => { e.preventDefault(); handleNext() }}>
                        <Grid container spacing={3}>
                            <StepContent step={activeStep} onSelectOffer={ handleChosenOffer } />

                            {
                                paymentErrorMessage
                                    ? <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', padding: 12 }}>
                                        <Warning style={{ color: '#D13838', marginRight: 16 }}/>
                                        <p style={{ color: '#D13838' }}>{ paymentErrorMessage }</p>
                                    </div> : <></>
                            }

                            { activeStep !== 0 ?
                                <Grid container item justify="flex-end">
                                    <Button disabled={activeStep === 0} className={classes.button} onClick={handleBack}>
                                        Précédent
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {
                                            loading
                                                ?
                                                <CircularProgress size={24} />
                                                :
                                                activeStep === 2 ? 'Payer' : 'Suivant'
                                        }
                                    </Button>
                                </Grid>
                                : <></>
                            }
                        </Grid>
                    </form>
                }
            </Box>

            <ModalComponent isOpen={ isModalOpen }
                            onClose={ () => setModalOpen(false) }>
                <h2 style={{ marginTop: 0 }}>Sélection incorrecte</h2>
                <p>Vous devez encore sélectionner { leftTipstersToSelect <= 1 ? '1 tipster' : `${leftTipstersToSelect} tipsters` } </p>
            </ModalComponent>
        </>
    );
}

export const stripeDataObjectConverter = (firstname, lastname, email, cardElement) => ({
    payment_method: {
        card: cardElement,
        billing_details: {
            email,
            name: `${firstname} ${lastname}`,
            phone: null
        },
    },
})

export default withRouter(Steppers);
