import { Link } from "react-router-dom";
import { dashboardCellType } from "../../../types/dashboard-cell/DashboardCellType";
import './DashboardCell.scss';

const DashboardCellComponent = ({ label, backgroundImage, to }) => {
    return (
        <Link to={ to} className="dashboard-cell">
            { label }
        </Link>
    );
}

DashboardCellComponent.prototype = dashboardCellType;

export default DashboardCellComponent;
