import './TelegramBanner.scss';
import Ticker from "react-ticker";
import { isTablet, isMobile } from "react-device-detect";

export default function TelegramBannerComponent() {

  const shouldRenderTicker = () => {
    return isTablet || isMobile;
  }

  const getLink = () => {
      return (
          <>
              <a href={ process.env.REACT_APP_SOCIAL_NETWORK_TELEGRAM_URL } target="_blank" rel="noreferrer noopener">
                  Cliquez ici pour être alerté en temps réel sur Telegram des pronos mis en ligne.
              </a>
          </>

      )
  }

  return (
        <div id="telegram-banner">
            {
                shouldRenderTicker ()
                    ? <Ticker mode="await">
                        {({ index }) => (
                            getLink()
                        )}
                    </Ticker>
                    : getLink()
            }
        </div>
  );
}
