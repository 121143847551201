import React, { Component } from "react";
import './TipsterAwaitingValidationPrognoses.scss';
import CardComponent from "../../../../components/card/Card";
import ApiClient from "../../../../services/api-client";
import AuthenticationService from "../../../../services/authentication/AuthenticationService";
import TipsterAwaitingValidationCellComponent from "../../../../components/tipster/tipster-awaiting-validation-cell/TipsterAwaitingValidationCell";
import { Skeleton } from "@material-ui/lab";

export default class TipsterAwaitingValidationPrognosesPage extends Component {

    api;
    authenticationService = new AuthenticationService();

    fakePrognosesList = new Array(3).fill(undefined);

    state = {
        prognoses: [],
        isLoading: true,
    }

    componentDidMount() {
        this.api = new ApiClient();
        this.api.setBearerAuth(this.authenticationService.getToken());

        this.api.tipster.getAwaitingValidationPrognoses()
            .then(list => {
                this.setState({ prognoses: list, isLoading: false });
            })
            .catch(error => {
                console.log(error);
            });
    }

    removePrognosisFromList = (id) => {
        const prognoses = this.state.prognoses;
        const reducedList = prognoses.filter(prognosis => prognosis.id !== id);
        this.setState({ prognoses: reducedList });
    }

    render() {
        return (
            <div id="boxed-content">
                <CardComponent type="dashboard">
                    <h1 id="dashboard-title">Pronostics en attente de validation</h1>

                    {
                        this.state.isLoading
                            ? this.fakePrognosesList.map((prognosis, index) => <Skeleton key={ index }
                                                                                                 variant="rect"
                                                                                                 width="100%"
                                                                                                 height={97}/>)
                            : this.state.prognoses.map(prognosis =>
                                <TipsterAwaitingValidationCellComponent key={ prognosis.id }
                                                                        prognosis={ prognosis }
                                                                        onComplete={ this.removePrognosisFromList }/>
                              )
                    }
                </CardComponent>
            </div>
        );
    }
}
