import React from 'react';
import PropTypes from "prop-types";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";

const SelectComponent = ({ id, label, value, defaultValue, options, isInvalid = false, invalidText = 'Ce champs est requis.',
                            onComplete, hasMarginBottom = false }) => {

    return (
        <>
            <FormControl fullWidth={ true }
                         error={ isInvalid }
                         style={ hasMarginBottom ? { 'marginBottom': '10px' } : null }>
                <InputLabel id={ id + 'label' }>{ label }</InputLabel>
                <Select style={{ fontSize: 18 }}
                        labelId={ id + 'label' }
                        id={ id }
                        value={ value ? value : "" }
                        defaultValue={ defaultValue ? defaultValue : "" }
                        onChange={ onComplete }>
                    { options.map((opt, index) => <MenuItem key={ index } selected={ opt.value === value } value={ opt.value }>{ opt.label }</MenuItem>) }
                </Select>
                <FormHelperText>{ isInvalid ? invalidText : " " }</FormHelperText>
            </FormControl>
        </>
    );
};

SelectComponent.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
    })),
    isInvalid: PropTypes.bool,
    invalidText: PropTypes.string,
    onComplete: PropTypes.func.isRequired,
    hasMarginBottom: PropTypes.bool
};

export default SelectComponent;
