import './Modal.scss';
import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {makeStyles} from "@material-ui/styles";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '100%',
        maxWidth: 550,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        boxSizing: "border-box",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        '&:focus': {
            outline: 'none'
        }
    },
}));

const ModalComponent = ({ isOpen, onClose, children }) => {

    const classes = useStyles();

    const [modalStyle] = useState(getModalStyle);

    useEffect(() => {

    }, []);

    return (
        <Modal
            open={ isOpen }
            onClose={ onClose }
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div style={ modalStyle } className={classes.paper}>
                { children }
            </div>
        </Modal>
    )
}

export default ModalComponent;
