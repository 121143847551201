import './WebsiteTerms.scss';
import CardComponent from "../../../components/card/Card";

export default function WebsiteTermsPage() {
    return (
        <CardComponent type="terms">
            <h1>Conditions générales d'utilisation et de vente</h1>

            <p>Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site : https://www.sportsbetnews.fr les informations suivantes :</p>


            <h3 className="term-title">1. Objet</h3>
            <p>Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation des services proposés sur le site (ci-après : les « Services »), ainsi que de définir les droits et obligations des parties dans ce cadre. Elles sont notamment accessibles et imprimables à tout moment par un lien direct en bas de la page d’accueil du site.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Elles peuvent être complétées, le cas échéant, par des conditions d’utilisation particulières à certains Services. En cas de contradiction, les conditions particulières prévalent sur ces conditions générales.</p>


            <h3 className="term-title">2. Exploitant des Services</h3>
            <p>Les Services sont exploités par la société SAS SPORT BREAK NEWS, Société Anonyme Simplifiée au capital social de 20000 euros, immatriculée au RCS d’Avignon sous le n° 885 149 880, dont le siège social est situé au 1921 route de la croisière – 84500 Bollène (ci-après : « SportsBetNews »).</p>
            <div className="term-paragraphs-spacer"/>
            <p>SportsBetNews peut être contactée aux coordonnées suivantes :</p>
            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>Adresse postale : <b>1921 route de la croisière – 84500 Bollène</b></p>
            <p>Adresse électronique : <b>contact@sportsbetnews.fr</b></p>


            <h3 className="term-title">3. Accès au site et aux Services</h3>
            <p>3.1 Hébergement</p>
            <p>L’hebergeur du site est : <b>Firebase / 1600 Amphitheatre Parkway, Mountain View, California 94043, USA</b></p>
            <div className="term-paragraphs-spacer"/>
            <p>3.2 Accessibilité</p>
            <p>Les Services sont accessibles, sous réserve des restrictions prévues sur le site :</p>
            <ul className="term-unordered-list">
                <li>à toute personne physique majeure disposant de la pleine capacité juridique pour s’engager au titre des présentes conditions générales. La personne physique qui ne dispose pas de la pleine capacité juridique ne peut accéder au Site et aux Services qu’avec l’accord de son représentant légal.</li>
                <li>à toute personne morale agissant par l’intermédiaire d’une personne physique disposant de la capacité juridique pour contracter au nom et pour le compte de la personne morale.</li>
            </ul>


            <h3 className="term-title">4. Acceptation des conditions générales</h3>
            <p>L’acceptation des présentes conditions générales est matérialisée par une case à cocher dans le formulaire d’inscription. Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non avenue. L’Utilisateur qui n’accepte pas d’être lié par les présentes conditions générales ne doit pas utiliser les Services.</p>


            <h3 className="term-title">5. Inscription sur le site</h3>
            <p>5.1 L’utilisation des Services nécessite que l’Utilisateur s’inscrive sur le site, en remplissant le formulaire prévu à cet effet. L’Utilisateur doit fournir l’ensemble des informations marquées comme obligatoires. Toute inscription incomplète ne sera pas validée.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’inscription entraîne automatiquement l’ouverture d’un compte au nom de l’Utilisateur (ci-après : le « Compte »), lui donnant accès à un espace personnel (ci-après : l’« Espace Personnel ») qui lui permet de gérer son utilisation des Services sous une forme et selon les moyens techniques que SportsBetNews juge les plus appropriés pour rendre lesdits Services.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur garantit que toutes les informations qu’il donne dans le formulaire d’inscription sont exactes, à jour et sincères et ne sont entachées d’aucun caractère trompeur.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Il s’engage à mettre à jour ces informations dans son Espace Personnel en cas de modifications, afin qu’elles correspondent toujours aux critères susvisés.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur est informé et accepte que les informations saisies aux fins de création ou de mise à jour de son Compte vaillent preuve de son identité. Les informations saisies par l’Utilisateur l’engagent dès leur validation.</p>
            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>5.2 L’Utilisateur peut accéder à tout moment à son Espace Personnel après s’être identifié à l’aide de son identifiant de connexion ainsi que de son mot de passe.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur s’engage à utiliser personnellement les Services et à ne permettre à aucun tiers de les utiliser à sa place ou pour son compte, sauf à en supporter l’entière responsabilité.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Il est pareillement responsable du maintien de la confidentialité de son identifiant et de son mot de passe. Il doit immédiatement contacter SportsBetNews aux coordonnées mentionnées à l’article 2 des présentes s’il remarque que son Compte a été utilisé à son insu. Il reconnaît à SportsBetNews le droit de prendre toutes mesures appropriées en pareil cas.</p>


            <h3 className="term-title">6. Description des Services</h3>
            <p>L’Utilisateur a accès aux Services décrits sur le site, sous une forme et selon les fonctionnalités et moyens techniques que SportsBetNews juge les plus appropriés.</p>


            <h3 className="term-title">7. Services</h3>
            <p>Les Services proposés par SportsBetNews sont fournis sous forme d’abonnement</p>
            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>

            <p>7.1 Prix des Services</p>
            <p>Le prix des Services est indiqué sur le site.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Sauf mention contraire, ils sont exprimés en euros et toutes taxes françaises comprises.</p>
            <div className="term-paragraphs-spacer"/>
            <p>SportsBetNews se réserve le droit, à sa libre discrétion et selon des modalités dont elle sera seule juge, de proposer des offres promotionnelles ou réductions de prix.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>7.2 Facturation</p>
            <p>Les Services font l’objet de factures qui sont communiquées à l’Utilisateur par tout moyen utile.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>7.3 Modalités de paiement</p>
            <p>Les modalités de paiement du prix des Services sont décrites sur le site.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Le paiement s’effectue par prélèvement automatique à partir du numéro de carte bancaire de l’Utilisateur.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Le prélèvement est mis en œuvre par le prestataire de paiement désigné sur le site, qui seul conserve les coordonnées bancaires de l’Utilisateur à cette fin. SportsBetNews ne conserve aucune coordonnée bancaire.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur garantit à SportsBetNews qu’il dispose des autorisations nécessaires pour utiliser le mode de paiement choisi. Il s’engage à prendre les mesures nécessaires afin que le prélèvement automatique du prix des Services puisse être effectué.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>7.5 Retards et incidents de paiement</p>
            <p>L’Utilisateur est informé et accepte expressément que tout retard de paiement de tout ou partie d’une somme due à son échéance entraînera automatiquement, sans préjudice des dispositions de l’article 12 et sans mise en demeure préalable :</p>
            <div className="term-paragraphs-spacer"/>
            <p>(i) la déchéance du terme de l’ensemble des sommes dues par l’Utilisateur et leur exigibilité immédiate ;</p>
            <div className="term-paragraphs-spacer"/>
            <p>(ii) la suspension immédiate des Services en cours jusqu’au complet paiement de l’intégralité des sommes dues par l’Utilisateur ;</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>7.6 Renouvellement des services et remboursement</p>
            <p>L’abonnement de l’Utilisateur se renouvellera automatiquement à la fin de la période de l’abonnement, à moins que celui-ci ne ne fasse une demande d’annulation par mail avant la fin de la période d’abonnement en cours. L’annulation entrera en vigueur le lendemain du dernier jour de la période d’abonnement en cours, et l’Utilisateur sera rétrogradé au Service gratuit.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>7.7 Absence de droit de rétractation</p>
            <p>Conformément aux dispositions de l’article L. 121-20-2 1° du Code de la Consommation, l’Utilisateur reconnaît et accepte expressément que la fourniture du Service SportsBetNews commence immédiatement après la validation de sa souscription à l’offre SportsBetNews soit avant la fin du délai de quatorze jours francs prévu par le Code de la Consommation et (ii) reconnaît et accepte en conséquence de ne pas bénéficier du droit de rétractation lié à la vente en ligne ou à distance. En conséquence, aucune demande de rétractation, d’annulation ou de remboursement ne sera recevable pour la période souscrite.</p>


            <h3 className="term-title">8. Données</h3>
            <p>L’Utilisateur reconnaît et accepte expressément :</p>
            <div className="term-paragraphs-spacer"/>
            <p>(i) que les données recueillies sur le site et sur les équipements informatiques de SportsBetNews font foi de la réalité des opérations intervenues dans le cadre des présentes ;</p>
            <div className="term-paragraphs-spacer"/>
            <p>(ii) que ces données constituent le seul mode de preuve admis entre les parties, notamment pour le calcul des sommes dues à SportsBetNews.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur peut accéder à ces données dans son Espace Personnel.</p>


            <h3 className="term-title">9. Obligations de l’Utilisateur</h3>
            <p>Sans préjudice des autres obligations prévues aux présentes, l’Utilisateur s’engage à respecter les obligations qui suivent :</p>
            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>

            <p>9.1 L’Utilisateur s’engage, dans son usage des Services, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre public.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Il est notamment seul responsable du bon accomplissement de toutes les formalités notamment administratives, fiscales et/ ou sociales et de tous les paiements de cotisations, taxes ou impôts de toutes natures qui lui incombent, le cas échéant, en relation avec son utilisation des Services. La responsabilité de SportsBetNews ne pourra en aucun cas être engagée à ce titre.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.2 L’Utilisateur reconnaît avoir pris connaissance sur le site des caractéristiques et contraintes, notamment techniques, de l’ensemble des Services. Il est seul responsable de son utilisation des Services.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.3 L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite qu’il soit connecté à internet et que la qualité des Services dépend directement de cette connexion, dont il est seul responsable.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.4 L’Utilisateur est également seul responsable des relations qu’il pourra nouer avec les autres Utilisateurs et des informations qu’il leur communique dans le cadre des Services. Il lui appartient d’exercer la prudence et le discernement appropriés dans ces relations et communications. L’Utilisateur s’engage en outre, dans ses échanges avec les autres Utilisateurs, à respecter les règles usuelles de politesse et de courtoisie.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.5 L’Utilisateur s’engage à faire un usage strictement personnel des Services. Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des présentes à un tiers, de quelque manière que ce soit.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.6 L’Utilisateur s’engage à fournir à SportsBetNews toutes les informations nécessaires à la bonne exécution des Services. Plus généralement, l’Utilisateur s’engage à coopérer activement avec SportsBetNews en vue de la bonne exécution des présentes.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.7 L’Utilisateur est seul responsable des contenus de toute nature (rédactionnels, graphiques, audiovisuels ou autres, en ce compris la dénomination et/ou l’image éventuellement choisies par l’Utilisateur pour l’identifier sur le site) qu’il diffuse dans le cadre des Services (ci-après désignés : les « Contenus »).</p>
            <div className="term-paragraphs-spacer"/>
            <p>Il garantit à SportsBetNews qu’il dispose de tous les droits et autorisations nécessaires à la diffusion de ces Contenus.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Il s’engage à ce que lesdits Contenus soient licites, ne portent pas atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune disposition législative ou règlementaire et plus généralement, ne soient aucunement susceptibles de mettre en jeu la responsabilité civile ou pénale de SportsBetNews.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur s’interdit ainsi de diffuser, notamment et sans que cette liste soit exhaustive :</p>
            <ul>
                <li>des Contenus pornographiques, obscènes, indécents, choquants ou inadaptés à un public familial, diffamatoires, injurieux, violents, racistes, xénophobes ou révisionnistes,</li>
                <li>des Contenus contrefaisants,</li>
                <li>des Contenus attentatoires à l’image d’un tiers,</li>
                <li>des Contenus mensongers, trompeurs ou proposant ou promouvant des activités illicites, frauduleuses ou trompeuses,</li>
                <li>des Contenus nuisibles aux systèmes informatiques de tiers (tels que virus, vers, chevaux de Troie, etc.),</li>
                <li>et plus généralement des Contenus susceptibles de porter atteinte aux droits de tiers ou d’être préjudiciables à des tiers, de quelque manière et sous quelque forme que ce soit.</li>
            </ul>
            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.8 L’Utilisateur reconnaît que les Services lui offrent une solution supplémentaire mais non alternative des moyens qu’il utilise déjà par ailleurs pour atteindre le même objectif et que cette solution ne saurait se substituer à ces autres moyens.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.9 L’Utilisateur doit prendre les mesures nécessaires pour sauvegarder par ses propres moyens les informations de son Espace Personnel qu’il juge nécessaires, dont aucune copie ne lui sera fournie.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>9.10 L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite qu’il soit connecté à internet et que la qualité des Services dépend directement de cette connexion, dont il est seul responsable.</p>


            <h3 className="term-title">10. Garantie de l’Utilisateur</h3>
            <p>L’Utilisateur garantit SportsBetNews contre toutes plaintes, réclamations, actions et/ou revendications quelconques que SportsBetNews pourrait subir du fait de la violation, par l’Utilisateur de l’une quelconque de ses obligations ou garanties aux termes des présentes conditions générales.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Il s’engage à indemniser SportsBetNews de tout préjudice qu’elle subirait et à lui payer tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.</p>


            <h3 className="term-title">11. Comportements prohibés</h3>
            <p>11.1 Il est strictement interdit d’utiliser les Services aux fins suivantes :</p>
            <ul>
                <li>l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,</li>
                <li>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</li>
                <li>l’intrusion dans le système informatique d’un tiers ou toute activité de nature à nuire, contrôler, interférer ou intercepter tout ou partie du système informatique d’un tiers, en violer l’intégrité ou la sécurité,</li>
                <li>l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,</li>
                <li>les manipulations destinées à améliorer le référencement d’un site tiers,</li>
                <li>l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,</li>
                <li>et plus généralement toute pratique détournant les Services à des fins autres que celles pour lesquelles ils ont été conçus.</li>
            </ul>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>11.2 Il est strictement interdit aux Utilisateurs de copier et/ou de détourner à leurs fins ou à celles de tiers le contenu, le concept, les technologies ou tout autre élément du site de SportsBetNews.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>11.3 Sont également strictement interdits : (i) tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité des Services, (ii) toutes intrusions ou tentatives d’intrusions dans les systèmes de SportsBetNews, (iii) tous détournements des ressources système du site, (iv) toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de ce dernier, (v) toutes atteintes aux mesures de sécurité et d’authentification, (vi) tous actes de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de SportsBetNews ou des usagers de son site, et enfin plus généralement (vii) tout manquement aux présentes conditions générales.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>11.4 Il est strictement interdit de monnayer, vendre ou concéder tout ou partie de l’accès aux Services ou au site, ainsi qu’aux informations qui y sont hébergées et/ou partagées.</p>


            <h3 className="term-title">12. Sanctions des manquements</h3>
            <p>En cas de manquement à l’une quelconque des dispositions des présentes conditions générales ou plus généralement, d’infraction aux lois et règlements en vigueur par un Utilisateur, SportsBetNews se réserve le droit de prendre toute mesure appropriée et notamment de :</p>
            <div className="term-paragraphs-spacer"/>
            <p>(i) suspendre ou résilier l’accès aux Services de l’Utilisateur, auteur du manquement ou de l’infraction, ou y ayant participé,</p>
            <div className="term-paragraphs-spacer"/>
            <p>(ii) supprimer tout contenu mis en ligne sur le site,</p>
            <div className="term-paragraphs-spacer"/>
            <p>(iii) publier sur le site tout message d’information que SportsBetNews jugera utile,</p>
            <div className="term-paragraphs-spacer"/>
            <p>(iv) avertir toute autorité concernée,</p>
            <div className="term-paragraphs-spacer"/>
            <p>(v) engager toute action judiciaire.</p>


            <h3 className="term-title">13. Responsabilité et garantie de SportsBetNews</h3>
            <p>13.1 SportsBetNews s’engage à fournir les Services avec diligence et selon les règles de l’art, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent expressément.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.2 SportsBetNews n’a pas connaissance des Contenus mis en ligne par les Utilisateurs dans le cadre des Services, sur lesquels elle n’effectue aucune modération, sélection, vérification ou contrôle d’aucune sorte et à l’égard desquels elle n’intervient qu’en tant que prestataire d’hébergement.</p>
            <div className="term-paragraphs-spacer"/>
            <p>En conséquence, SportsBetNews ne peut être tenue pour responsable des Contenus, dont les auteurs sont des tiers, toute réclamation éventuelle devant être dirigée en premier lieu vers l’auteur des Contenus en question.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Les Contenus préjudiciables à un tiers peuvent faire l’objet d’une notification à SportsBetNews selon les modalités prévues par l’article 6 I 5 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, SportsBetNews se réservant de prendre les mesures décrites à l’article 12.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.3 SportsBetNews décline toute responsabilité en cas de perte éventuelle des informations accessibles dans l’Espace Personnel de l’Utilisateur, celui-ci devant en sauvegarder une copie et ne pouvant prétendre à aucun dédommagement à ce titre.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.4 SportsBetNews s’engage à procéder régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité du site. A ce titre, SportsBetNews se réserve la faculté d’interrompre momentanément l’accès au site pour des raisons de maintenance. De même, SportsBetNews ne saurait être tenue responsable des difficultés ou impossibilités momentanées d’accès au site qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.5 SportsBetNews ne garantit pas aux Utilisateurs (i) que les Services, soumis à une recherche constante pour en améliorer notamment la performance et le progrès, seront totalement exempts d’erreurs, de vices ou défauts, (ii) que les Services, étant standard et nullement proposés à la seule intention d’un Utilisateur donné en fonction de ses propres contraintes personnelles, répondront spécifiquement à ses besoins et attentes.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.6 En tout état de cause, la responsabilité susceptible d’être encourue par SportsBetNews au titre des présentes est expressément limitée aux seuls dommages directs avérés subis par l’Utilisateur.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.7 SportsBetNews n’est en aucun cas responsable de l’utilisation que fait l’Utilisateur des conseils reçus. Il en revient de son entière responsabilité de respecter la législation française.</p>

            <div className="term-paragraphs-spacer"/>
            <div className="term-paragraphs-spacer"/>
            <p>13.8 L’Utilisateur est entièrement conscient du risque pris lorsqu’il place un pari sur un événement sportif. SportsBetNews est une société de conseil et ne peut garantir en aucun cas des gains certains. L’utilisateur conserve l’entière responsabilité de ses jeux.</p>


            <h3 className="term-title">14. Propriété intellectuelle</h3>
            <p>Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de toute nature (textes, images, visuels, musiques, logos, marques, base de données, etc …) exploités par SportsBetNews au sein du site sont protégés par tous droits de propriété intellectuelle ou droits des producteurs de bases de données en vigueur. Tous désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus généralement, tous actes de reproduction, représentation, diffusion et utilisation de l’un quelconque de ces éléments, en tout ou partie, sans l’autorisation de SportsBetNews sont strictement interdits et pourront faire l’objet de poursuites judiciaires.</p>


            <h3 className="term-title">15. Données à caractère personnel</h3>
            <p>Les bases de données sont protégées par les dispositions de la loi du 20 juin 2018 relative à la protection juridique des bases de données.</p>


            <h3 className="term-title">16. Publicité</h3>
            <p>SportsBetNews se réserve la faculté d’insérer sur toute page du site et dans toute communication aux Utilisateurs tous messages publicitaires ou promotionnels sous une forme et dans des conditions dont SportsBetNews sera seule juge.</p>


            <h3 className="term-title">17. Liens et sites tiers</h3>
            <p>SportsBetNews ne pourra en aucun cas être tenue pour responsable de la disponibilité technique de sites internet ou d’applications mobiles exploités par des tiers (y compris ses éventuels partenaires) auxquels l’Utilisateur accéderait par l’intermédiaire du site.</p>
            <div className="term-paragraphs-spacer"/>
            <p>SportsBetNews n’endosse aucune responsabilité au titre des contenus, publicités, produits et/ou services disponibles sur de tels sites et applications mobiles tiers dont il est rappelé qu’ils sont régis par leurs propres conditions d’utilisation.</p>
            <div className="term-paragraphs-spacer"/>
            <p>SportsBetNews n’est pas non plus responsable des transactions intervenues entre l’Utilisateur et un quelconque annonceur, professionnel ou commerçant (y compris ses éventuels partenaires) vers lequel l’Utilisateur serait orienté par l’intermédiaire du site et ne saurait en aucun cas être partie à quelques litiges éventuels que ce soit avec ces tiers concernant notamment la livraison de produits et/ou services, les garanties, déclarations et autres obligations quelconques auxquelles ces tiers sont tenus.</p>


            <h3 className="term-title">18. Durée des Services, désinscription</h3>
            <p>Les Services sont souscrits pour une durée indéterminée.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur peut se désinscrire des Services à tout moment, en adressant une demande à cet effet à SportsBetNews par email, aux coordonnées mentionnées à l’article 2.</p>
            <div className="term-paragraphs-spacer"/>
            <p>La désinscription est effective à la fin des 30 jours d’abonnement en cours. Elle entraîne la suppression automatique du Compte de l’Utilisateur dès le 1er jour suivant la fin de la période mensuelle d’abonnement.</p>


            <h3 className="term-title">19. Modifications</h3>
            <p>SportsBetNews se réserve la faculté de modifier à tout moment les présentes conditions générales.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur sera informé de ces modifications par tout moyen utile.</p>
            <div className="term-paragraphs-spacer"/>
            <p>L’Utilisateur qui n’accepte pas les conditions générales modifiées doit se désinscrire des Services selon les modalités prévues à l’article 18.</p>
            <div className="term-paragraphs-spacer"/>
            <p>Tout Utilisateur qui a recours aux Services postérieurement à l’entrée en vigueur des conditions générales modifiées est réputé avoir accepté ces modifications.</p>


            <h3 className="term-title">20. Langue</h3>
            <p>Dans l’hypothèse d’une traduction des présentes conditions générales dans une ou plusieurs langues, la langue d’interprétation sera la langue française en cas de contradiction ou de contestation sur la signification d’un terme ou d’une disposition.</p>


            <h3 className="term-title">21. Loi applicable et juridiction</h3>
            <p>Les présentes conditions générales sont régies par la loi française.</p>
            <div className="term-paragraphs-spacer"/>
            <p>En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes conditions générales, les parties conviennent que les tribunaux d’Avignon seront exclusivement compétents pour en juger, sauf règles de procédures impératives contraires.</p>


            <h3 className="term-title">22. Entrée en vigueur</h3>
            <p>Les présentes conditions générales sont entrées en vigueur le 12 juillet 2019.</p>


            <h3 className="term-title">23. Jeu responsable</h3>
            <p>La dépendance aux jeux d’argent est un problème très sérieux. En cas de dépendance, nous vous invitons à consulter les sites internet ci-dessous ou à vous rapprocher des associations suivantes :</p>
            <div className="term-paragraphs-spacer"/>
            <p><b>SOS Joueurs / 01 45 38 57 57 – 22 rue Ducouédic 75014 PARIS France (http://www.sosjoueurs.org/)</b></p>
            <p><b>Joueurs anonymes / 01 45 01 95 46 – 72 rue Boissière 75016 Paris France</b></p>
        </CardComponent>
    )
}
