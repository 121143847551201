import React from "react";
import './OfferTipsterPreview.scss';

const classNames = require('classnames');

class OfferTipsterPreview extends React.Component {

    state = {
        isSelected: false
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isSelected !== state.isSelected) {
            return {
                isSelected: props.isSelected
            };
        }

        return null;
    }

    render() {
        const { tipster, isAdmin = false, onClick } = this.props;

        const offerTipsterPreviewClassNames = classNames({
            'membership-tipster-preview-container': true,
            'selected': this.state.isSelected,
        });

        return (
            <div className={ offerTipsterPreviewClassNames } onClick={ () => onClick(tipster.slug) }>
                <img src={ tipster.picture } alt={ tipster.name }/>
                <p className="membership-offer-title">{ isAdmin ? tipster.nickname : tipster.name }</p>
            </div>
        )
    }
}

export default OfferTipsterPreview;
