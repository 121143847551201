import React, {useEffect, useState} from 'react';
import {
    Grid,
    Typography
} from "@material-ui/core";
import { useStateValue } from "../membership-state-context";
import './MembershipTipstersListForm.scss';
import OfferTipsterPreview from "./TipstersPreview/OfferTipsterPreview";
import {getTipstersList} from "../../../helpers/utils";

const MembershipTipstersListForm = () => {

    const [{ formValues }, dispatch] = useStateValue();
    const [ maxTipstersAmount, setMaxTipstersAmount ] = useState(0);
    const [ selectedTipsters, setSelectedTipsters ] = useState([]);

    useEffect(() => {
        const offer = formValues.selected_offer;
        const tipstersList = formValues.tipsters_list;
        let maxTipsters;

        switch (offer) {
            case "one":
                maxTipsters = 1;
                break;
            case "two":
                maxTipsters = 2;
                break;
            case "three":
                maxTipsters = getTipstersList().length;
                break;
            default:
                maxTipsters = 1;
        }

        setMaxTipstersAmount(maxTipsters);

        if (tipstersList.length > 0 && tipstersList.length <= maxTipsters) {
            setSelectedTipsters(tipstersList);
        }
    }, [formValues.selected_offer, formValues.tipsters_list]);

    const onTipsterClicked = (tipster) => {
        let copiedList = [ ...selectedTipsters ];

        if (!selectedTipsters.includes(tipster)) {
            if (selectedTipsters.length < maxTipstersAmount) {
                copiedList.push(tipster);
            } else {
                copiedList.shift();
                copiedList.push(tipster);
            }
        } else {
            copiedList = copiedList.filter(currentTipster => currentTipster !== tipster);
        }

        setSelectedTipsters(copiedList);

        dispatch({
            type: 'editFormValue',
            key: 'tipsters_list',
            value: copiedList
        });
    }

    return <>
        <Grid item xs={12}>
            <Typography variant="h6">Sélectionnez { maxTipstersAmount <= 1 ? `1 tipster` : `${maxTipstersAmount} tipsters`} :</Typography>
        </Grid>
        {
            tipstersList.map((tipster, index) => {
                return <Grid item xs={12} sm={2} key={ index }>
                    <OfferTipsterPreview tipster={ tipster }
                                         isSelected={ selectedTipsters.includes(tipster.slug) }
                                         onClick={ onTipsterClicked } />
                </Grid>
            })
        }
    </>
}

const tipstersList = getTipstersList();

export default MembershipTipstersListForm;
