import React, {Component} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {unstable_createMuiStrictModeTheme as createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {materialStyles} from "../assets/styles/material-styling";
import {Role} from "../models/role/RoleModel";
import {fb} from '../services/firebase';
import {UserStore} from "../stores/UserStore";
import {isDev} from "../helpers/utils";
import './App.scss';
import HomePage from "./home/Home";
import LoadingHomePage from "./home/loading-home/LoadingHome";
import LoginPage from "./authentication/login/Login";
import RegisterPage from "./authentication/register/Register";
import ForgottenPasswordPage from "./authentication/forgotten-password/ForgottenPassword";
import SetNewPasswordPage from "./authentication/forgotten-password/set-new-password/SetNewPassword";
import MembershipPage from "./membership/Membership";
import MyAccountPage from "./my-account/MyAccount";
import NotFoundPage from "./not-found/NotFound";
import TipsterDashboardPage from "./dashboard/tipster/TipsterDashboard";
import TipsterPublishPrognosisPage from "./dashboard/tipster/publish-prognosis/TipsterPublishPrognosis";
import TipsterUpdatePrognosis from "./dashboard/tipster/update-prognosis/TipsterUpdatePrognosis";
import TipsterAwaitingValidationPage
  from "./dashboard/tipster/awaiting-validation-prognoses/TipsterAwaitingValidationPrognoses";
import TipsterPrognosesListPage from "./dashboard/tipster/prognoses-list/TipsterPrognosesList";
import ApiClient from "../services/api-client";
import HeaderComponent from "../components/header/Header";
import FooterComponent from "../components/footer/Footer";
import TipsterMyAccount from "./my-account/tipster/TipsterMyAccount";
import WebsiteTermsPage from "./website/terms/WebsiteTerms";
import AffiliatePage from "./affiliate/Affiliate";
import AdminDashboardPage from "./dashboard/admin/AdminDashboard";
import AdminPublishPrognosis from "./dashboard/admin/publish-prognosis/AdminPublishPrognosis";
import AdminPrognosesList from "./dashboard/admin/prognoses-list/AdminPrognosesList";
import AdminUsersList from "./dashboard/admin/users-list/AdminUsersList";
import AdminUsersViewDetails from "./dashboard/admin/users-list/user-view-details/AdminUsersViewDetails";
import AdminUpdatePrognosisPage from "./dashboard/admin/prognoses-list/prognosis-update/AdminUpdatePrognosis";
import ScrollToTopOnRouteChange from "../components/utils/scroll-top-on-route-change/ScrollToTopOnRouteChange";
import CookiesBannerComponent from "../components/cookies-banner/CookiesBanner";

const theme = createMuiTheme(materialStyles.theme);

class App extends Component {

  api = new ApiClient();

  state = {
    isAuthenticated: false,
    user: {
      role: 0
    },
    hasLoadedAuthentication: false,
  };

  componentDidMount() {
    this.startRefreshTokenTimer();

    fb.auth().onIdTokenChanged(async user => {
      if (user) {
        const newToken = await user.getIdToken()
        this.api.user.storeToken(newToken);
      }
    });

    fb.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        this.api.user.storeToken(token);

        if (isDev()) {
          console.log(token);
        }

        this.api.setBearerAuth(token);

        const fetchedUser = await this.api.user.getUser(user.email);

        this.setState({
          user: fetchedUser,
          hasLoadedAuthentication: true,
          isAuthenticated: true,
        });

        UserStore.update(store => { store.user = fetchedUser });
      } else {
        this.setState({
          isAuthenticated: false,
          hasLoadedAuthentication: true,
        });

        UserStore.update(store => { store.user = null });
      }
    });

    fb.auth().onIdTokenChanged(token => {
      if (this.state.isAuthenticated && !token) {
        this.setState({
          isAuthenticated: false
        });
      }
    });
  }

  startRefreshTokenTimer = () => {
    const api = this.api;

    setInterval(() => {
      if (this.state.isAuthenticated) {
        fb.auth().currentUser.getIdToken(true)
            .then(token => {
              api.user.storeToken(token);
            }).catch(error => {
              console.error(error);
            });
      }
    }, 1000 * 60 * 50); // refresh token every 50mins since it expires every 60mins
  }

  render() {
    return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTopOnRouteChange>
              <HeaderComponent/>

              <div style={{ flex: 1 }}>
                {
                  this.state.hasLoadedAuthentication
                      ? <Switch>
                        <Route exact path="/" component={ HomePage }/>

                        { /* ADMIN ROUTES */}
                        <AdminRoute exact path="/admin" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ AdminDashboardPage }/>
                        <AdminRoute exact path="/admin/liste-pronostics" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ AdminPrognosesList }/>
                        <AdminRoute exact path="/admin/pronostic/:id" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ AdminUpdatePrognosisPage }/>
                        <AdminRoute exact path="/admin/nouveau-prono" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ AdminPublishPrognosis }/>
                        <AdminRoute exact path="/admin/liste-utilisateurs" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ AdminUsersList }/>
                        <AdminRoute exact path="/admin/utilisateur/:email" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ AdminUsersViewDetails }/>

                        { /* TIPSTER ROUTES */ }
                        <TipsterRoute exact path="/tipster" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ TipsterDashboardPage }/>
                        <TipsterRoute exact path="/tipster/nouveau-prono" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ TipsterPublishPrognosisPage }/>
                        <TipsterRoute exact path="/tipster/en-attente" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ TipsterAwaitingValidationPage }/>
                        <TipsterRoute exact path="/tipster/mes-pronos" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ TipsterPrognosesListPage }/>
                        <TipsterRoute exact path="/tipster/pronostic/:id" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ TipsterUpdatePrognosis }/>
                        <Route path="/tipster/:id" component={ TipsterMyAccount }/>

                        { /* COMMON ROUTES TODO: authorize this route again for all users */ }
                        { /* <TipsterRoute exact path="/mon-compte" authenticated={ this.state.isAuthenticated } role={ this.state.user.role } component={ MyAccountPage }/> */ }
                        <PrivateRoute exact path="/mon-compte" authenticated={ this.state.isAuthenticated } component={ MyAccountPage }/>

                        { /* REGISTERED ROUTES */ }
                        <Route exact path="/abonnement" component={ MembershipPage }/>

                        <AuthenticationRoute path="/connexion" authenticated={ this.state.isAuthenticated } component={ LoginPage }/>
                        <AuthenticationRoute path="/inscription" authenticated={ this.state.isAuthenticated } component={ RegisterPage }/>
                        <AuthenticationRoute path="/mot-de-passe-oublie" authenticated={ this.state.isAuthenticated } component={ ForgottenPasswordPage }/>
                        <AuthenticationRoute path="/redefinir-mot-de-passe" authenticated={ this.state.isAuthenticated } component={ SetNewPasswordPage }/>

                        <Route path="/affiliation" component={ AffiliatePage }/>
                        <Route path="/conditions-generales" component={ WebsiteTermsPage }/>

                        <Route path="/erreur" component={ NotFoundPage }/>
                        <Redirect from="*" to="/erreur"/>
                      </Switch>
                      : <Route exact path="/">
                        <LoadingHomePage/>
                      </Route>
                }
              </div>

              <CookiesBannerComponent/>

              <FooterComponent/>
            </ScrollToTopOnRouteChange>
          </BrowserRouter>
        </ThemeProvider>
    )
  }
}

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
      <Route
          {...rest}
          render={(props) => authenticated === true
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/connexion', state: { from: props.location } }} />}
      />
  )
}

function AdminRoute({ component: Component, authenticated, role, ...rest }) {

  const allowedRoles = [ Role.ADMIN ];

  return (
      <Route
          {...rest}
          render={(props) => authenticated === true && allowedRoles.includes(role)
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/erreur', state: {from: props.location} }} />}
      />
  )
}

function TipsterRoute({ component: Component, authenticated, role, ...rest }) {

  const allowedRoles = [ Role.TIPSTER ];

  return (
      <Route
          {...rest}
          render={(props) => authenticated === true && allowedRoles.includes(role)
              ? <Component {...props} />
              : <Redirect to={{ pathname: '/erreur', state: {from: props.location} }} />}
      />
  )
}

function AuthenticationRoute({ component: Component, authenticated, ...rest }) {
  return (
      <Route
          {...rest}
          render={(props) => authenticated === false
              ? <Component {...props} />
              : <Redirect to='/' />}
      />
  )
}

export default App;
