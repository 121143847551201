import React, { Component } from 'react';
import './ForgottenPassword.scss';
import {fb} from "../../../services/firebase";
import CardComponent from "../../../components/card/Card";
import Alert from "@material-ui/lab/Alert";
import InputComponent from "../../../components/utils/input/Input";
import {Link} from "react-router-dom";
import ButtonComponent from "../../../components/utils/button/Button";

const DEFAULT_STATE = {
    email: '',
    isInvalidEmail: false,
    hasSuccessfullySentEmail: false,
    errorMessage: null,
    isLoading: false
};

export default class ForgottenPasswordPage extends Component {
    state = { ...DEFAULT_STATE };

    onSetEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    onSubmitForm = async () => {
        if (this.state.isLoading) { return; }

        if (this.state.email !== '') {
            this.setLoading(true);
            this.clearFormErrors();

            fb.auth().sendPasswordResetEmail(this.state.email)
                .then(() => {
                    this.setState({
                        hasSuccessfullySentEmail: true,
                        isLoading: false,
                    });
                }).catch(error => {
                    let message;

                    switch (error.code) {
                        case "auth/user-not-found":
                            message = "Aucun compte avec cette adresse email n'a été trouvé."
                            break;
                        default:
                            message = error.message;
                    }

                    this.setState({
                        errorMessage: message,
                        isLoading: false
                    });
                });
        } else {
            if (!this.state.email) {
                this.setState({
                    isInvalidEmail: true
                });
            }
        }
    }

    setLoading = (isLoading) => {
        this.setState({
            isLoading
        });
    }

    clearFormErrors = () => {
        this.setState({
            isInvalidEmail: false,
            hasSuccessfullySentEmail: false,
            errorMessage: null
        });
    }

    render() {
        return (
            <CardComponent type="authentication">
                <h1>Mot de passe oublié</h1>

                {
                    this.state.errorMessage
                        ? <Alert severity="error" style={{ alignItems: 'center', marginBottom: '20px' }}>{ this.state.errorMessage }</Alert>
                        : <></>
                }

                {
                    this.state.hasSuccessfullySentEmail
                        ? <Alert severity="success" style={{ alignItems: 'center', marginBottom: '20px' }}>Un email avec un lien pour réinitialiser votre mot de passe vous a été envoyé. Si vous ne recevez pas le mail dans quelques instants, pensez à vérifier vos courriers indésirables.</Alert>
                        : <>
                            <InputComponent
                                id="forgotten-password__email-input"
                                label="Votre adresse email *"
                                isInvalid={ this.state.isInvalidEmail }
                                type="email"
                                onComplete={ this.onSetEmail }
                            />

                            <ButtonComponent
                                id="authentication-submit-button"
                                label="Réinitialiser"
                                onClick={ this.onSubmitForm }
                                isLoading={ this.state.isLoading }/>
                        </>
                }

                <Link className="authentication-link return-to-login" to="/connexion">
                    Retour à la connexion
                </Link>
            </CardComponent>
        );
    }
}
