import './Card.scss';

const CardComponent = ({ children, type }) => {

    return (
        <div className={`card ${type ? type + '-card' : ''}`} >
            { children }
        </div>
    )
}

export default CardComponent;
