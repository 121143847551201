import './AdminUsersList.scss';
import ApiClient from "../../../../services/api-client";
import React, {useEffect, useState} from "react";
import AuthenticationService from "../../../../services/authentication/AuthenticationService";
import CardComponent from "../../../../components/card/Card";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Link} from "react-router-dom";
import InputComponent from "../../../../components/utils/input/Input";

const AdminUsersList = () => {
    const api = new ApiClient();
    const authenticationService = new AuthenticationService();

    const [ usersList, setUsersList ] = useState([]);
    const [ filteredUsersList, setFilteredUsersList ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        api.setBearerAuth(authenticationService.getToken());

        api.admin.getAllUsers()
            .then(users => {
                setUsersList(users);
                setFilteredUsersList(users);
                setLoading(false);
            }).catch(error => {
                console.log(error);
            });
    }, []);

    const onSearchUser = (event) => {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            setFilteredUsersList(usersList);
        } else {
            const filteredList = usersList.filter(user => {
                return user.email.toLowerCase().includes(value) ||
                        user.lastName.toLowerCase().includes(value) ||
                        user.firstName.toLowerCase().includes(value) ||
                        user.stripeCustomerId.toLowerCase().includes(value);
            });

            setFilteredUsersList(filteredList);
        }
    }

    const convertDate = (date) => {
        return new Date(date).toLocaleDateString();
    }

    return (
        <div id="boxed-content" className="aligned-left">
            <CardComponent type="admin-dashboard-table">
                <h1 id="dashboard-title">Liste des utilisateurs</h1>

                {
                    loading
                        ? <>Chargement...</>
                        : <>
                            <InputComponent
                                id="admin_search_user"
                                label="Rechercher"
                                onComplete={ onSearchUser }
                            />

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">Nom et prénom</TableCell>
                                            { /* <TableCell align="left">Abonné</TableCell> */ }
                                            <TableCell align="left">Date de création</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filteredUsersList.length > 0
                                                ? filteredUsersList.map((user) => (
                                                    <TableRow component={ Link } to={ `/admin/utilisateur/${user.email}` } className="user-table-row" key={user.id}>
                                                        <TableCell align="left">{user.email}</TableCell>
                                                        <TableCell align="left">{user.lastName.toUpperCase() + " " + user.firstName}</TableCell>
                                                        { /* <TableCell align="left">{user.role > 1 ? "Oui" : "Non"}</TableCell> */ }
                                                        <TableCell align="left">{convertDate(user.createdAt)}</TableCell>
                                                    </TableRow>
                                                ))
                                                : <TableRow>
                                                    <TableCell>Aucun résultat ne correspond à cette recherche.</TableCell>
                                                    <TableCell/>
                                                    <TableCell/>
                                                    <TableCell/>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                }
            </CardComponent>
        </div>
    )
}

export default AdminUsersList;
