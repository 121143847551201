import './TipsterAwaitingValidationCell.scss';
import React, { useState } from "react";
import { prognosisType } from "../../../types/prognosis/PrognosisType";
import { PrognosisFinalType } from "../../../models/prognosis-final-type/PrognosisFinalTypeModel";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ButtonComponent from "../../utils/button/Button";
import SelectComponent from "../../utils/select/Select";
import ApiClient from "../../../services/api-client";
import AuthenticationService from "../../../services/authentication/AuthenticationService";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const FINAL_TYPE_OPTIONS = [
    { label: `Pronostic gagné`, value: PrognosisFinalType.WIN },
    { label: `Pronostic perdu`, value: PrognosisFinalType.LOSS },
    { label: `Pronostic remboursé`, value: PrognosisFinalType.REFUNDED },
    { label: `Pronostic annulé`, value: PrognosisFinalType.CANCELED },
];

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '100%',
        maxWidth: 550,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        boxSizing: "border-box",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        '&:focus': {
            outline: 'none'
        }
    },
}));

const TipsterAwaitingValidationCellComponent = ({ prognosis, onComplete }) => {
    const classes = useStyles();

    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const [finalType, setFinalType] = useState('');
    const [isInvalidFinalType, setInvalidFinalType] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const setValidatedPrognosis = async () => {
        if (isLoading) { return; }

        if (finalType === '') { return setInvalidFinalType(true); }

        setIsLoading(true);
        setInvalidFinalType(false);

        prognosis.finalType = finalType;

        const api = new ApiClient();
        const authenticationService = new AuthenticationService();
        api.setBearerAuth(authenticationService.getToken());

        try {
            await api.tipster.setAwaitingValidationPrognosisValidated(prognosis);
            handleClose();
            onComplete(prognosis.id);
        } catch (error) {
            console.log(error);
        }
    }

    const formatStartDate = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            hour12: false,
            minute: '2-digit'
        });
    }

    return (
        <>
            <div className="tipster-awaiting-validation-cell">
                <div className="tipster-awaiting-validation-cell__content-container">
                    <p className="player">
                        { prognosis.players[0] }
                        { prognosis.players[1]
                            ? <>
                                <span className="player versus">vs</span>
                                { prognosis.players[1] }
                            </>
                            : <></>
                        }
                    </p>

                    <p><u>Pronostic:</u> { prognosis.prognosis }</p>

                    <p className="starting-date">Débute le { formatStartDate(prognosis.startDate) }</p>
                </div>

                <ButtonComponent isLoading={ false }
                                 label={ "Valider"}
                                 onClick={ handleOpen } />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={ modalStyle } className={classes.paper}>
                    <h3>Veuillez choisir le résultat du match parmis la liste ci-dessous :</h3>

                    <SelectComponent id="tipster-validate-prognosis"
                                     label="Résultat *"
                                     value={ finalType }
                                     options={ FINAL_TYPE_OPTIONS }
                                     isInvalid={ isInvalidFinalType }
                                     onComplete={ (event) => setFinalType(event.target.value) }/>

                    <ButtonComponent style={{ marginTop: 30 }}
                                     isLoading={ isLoading }
                                     label={ "Mettre à jour"}
                                     onClick={ setValidatedPrognosis } />
                </div>
            </Modal>
        </>
    )
}

TipsterAwaitingValidationCellComponent.propTypes = {
    prognosis: prognosisType
}

export default TipsterAwaitingValidationCellComponent;
