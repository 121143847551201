export function isDev() {
    return process.env.REACT_APP_IS_PRODUCTION === "false"
}

export function getTipstersNames() {
    return [ 'nolike', 'crunch_bet', 'sandro', 'greg_mac' ];
}

export function getTipstersList() {
    return [
        {
            name: 'Nolike',
            picture: './assets/images/tipsters/nolike.jpg',
            slug: 'nolike',
        },
        {
            name: 'Crunch Bet',
            picture: './assets/images/tipsters/crunch_bet.jpg',
            slug: 'crunch_bet',
        },
        {
            name: 'Sandro',
            picture: './assets/images/tipsters/sandro.jpg',
            slug: 'sandro',
        },
        {
            name: 'Greg Mac',
            picture: './assets/images/tipsters/greg_mac.jpg',
            slug: 'greg_mac',
        },
    ]
}

String.prototype.toTitleCase = function() {
    return this.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
}
